@font-face {
  font-family: "CoFo Sans";
  src: url('./CoFoSans_V_2_008/CoFoSans-Medium.woff') format('woff'); 
  src: url('./CoFoSans_V_2_008/CoFoSans-Medium.woff2') format('woff2'); 
  font-weight: 500;
}

@font-face {
  font-family: "CoFo Sans";
  src: url('./CoFoSans_V_2_008/CoFoSans-Regular.woff') format('woff'); 
  src: url('./CoFoSans_V_2_008/CoFoSans-Regular.woff2') format('woff2'); 
  font-weight: 400;
}
* {
    padding: 0;
    margin: 0;
    list-style: none;
    outline: none;
    font-family:'Cofo Sans';
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-weight: 500;
  }

  
  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  
  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
  
  
  a {
    text-decoration: none;
  }

  .notfound {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    div{
      padding-top: 90px;
      height: 100%;
      width: 100%;
      a {

        width: 100%;
        height: 100%;
        img {
  
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  @mixin noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
  }
  