.button {
    cursor: pointer;
    padding: 7px 30px;
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    border-radius: 100px;
    background: $titleColor;
    border: none;
    transition: background 0.3s ease-in-out;
    &:hover {
      background: #f3f3b9;
      transition: background 0.3s ease-in-out;
    }
    @media screen and (max-width: 768px) {
      font-size: 16px;
      padding: 5px 15px;
    }
  }