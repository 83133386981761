.contacts--form {
  p {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    max-width: 250px;
    @media screen and (max-width: 1050px) {
    font-size: 16px;
    }
    @media screen and (max-width: 400px) {
      min-width: 300px;
      max-width: 300px;
    }
  }
  .form--container {
    textarea {
      padding: 15px 30px;
      margin-top: 30px;
      min-width: 650px;
      border-radius: 41px;
      border: 2px solid #000;
      min-height: 329px;
      font-size: 16px;
      font-weight: 400;
      color: #000;
      @media screen and (max-width: 1285px) {
        min-width: 490px;
        min-height: 290px;
      }
      @media screen and (max-width: 1050px) {
        min-width: 360px;
        min-height: 160px;
      }
      @media screen and (max-width: 400px) {
        min-width: 300px;
        max-width: 300px;
        min-height: 100px;
      }
    }
    p {
      color: #000;
      font-size: 31px;
      font-style: normal;
      font-weight: 500;
      margin: 30px 0 20px;
      @media screen and (max-width: 1050px) {
      min-width: 360px;
      text-align: start;
      font-size: 26px;
      }
      @media screen and (max-width: 400px) {
        min-width: 300px
      }
    }
    .form--inputs {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      input:first-child {
        display: flex;
        width: 449px;
        padding: 7px 10px;
        align-items: center;
        flex-shrink: 0;
        border-radius: 100px;
        border: 2px solid #000;
        @media screen and (max-width: 1285px) {
          width: 340px;
        }
        @media screen and (max-width: 1050px) {
          width: 100%;
        }
      }
      input:last-child {
        cursor: pointer;
        margin-left: 20px;
        padding: 7px 10px;
        text-align: center;
        min-width: 137px;
        border-radius: 100px;
        background: #e1e051;
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        transition: opacity 0.4s ease-in-out;
        border: 2px solid #e1e051;
        transition: background 0.3s ease-in-out;
        &:hover {
          transition: background 0.3s ease-in-out;
          background: #F3F3B9;
        }
        &:disabled {
          transition: opacity 0.4s ease-in-out;
          opacity: 0.5;
          transition: background 0.3s ease-in-out;
        }
        @media screen and (max-width: 1285px) {
          min-width: 130px;
        }
        @media screen and (max-width: 1050px) {
          width: 100%;
          margin-left: 0;
          margin-top: 20px;
        }
      }
      @media screen and (max-width: 1050px) {
        flex-direction: column;
        min-width: 360px;
      }
      @media screen and (max-width: 400px) {
        min-width: 300px
      }
    }
    .checkbox-container {
      margin: 20px 0;
      display: flex;
      align-items: center;
      input {
        position: relative;
        width: 20px;
        height: 20px;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none; 
        border-radius: 50%;
        border: 1px solid black;
        cursor: pointer;
        &:checked{
          background: black;
        }
      }
      p {
        color: rgba(0, 0, 0, 0.6);
        margin: 0 0 0 10px;
        max-height: 30px;
        min-width: 400px;
        font-size: 12px;
        a {
          color: rgba(0, 0, 0, 0.6);
          text-decoration: underline;
        }
        @media screen and (max-width: 1050px) {
          min-width: 300px;
        }
        @media screen and (max-width: 400px) {
          font-size: 10px;
          min-width: 260px;
          max-width: 260px;
         }
      }
    }
    @media screen and (max-width: 1050px) {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
    }
  }
  @media screen and (max-width: 400px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
