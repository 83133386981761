.reliability--container {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 80px 30px;
  p {
    max-width: 1050px;
    color: rgba(0, 0, 0, 0.5);
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    span {
      color: $defColor;
    }
    @media screen and (max-width: 1350px) {
      font-size: 35px;
    }
    @media screen and (max-width: 1250px) {
      font-size: 30px;
    }
    @media screen and (max-width: 768px) {
      font-size: 24px;
    }
  }

  .gaurantee--container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 65px 0 0;
    min-width: 1100px;
    justify-content: space-between;
    &_item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      min-height: 150px;
      max-width: 310px;
      min-width: 310px;
      h3 {
        display: flex;
        align-items: center;
        color: #000;
        text-align: center;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        min-height: 80px;
      }
      p {
        margin-top: 7px;
        color: $defColor;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        text-align: center;
        @media screen and (max-width: 768px) {
          font-size: 14px;
        }
      }
      &:nth-child(2) {
        border-left: 1px solid rgba(106, 106, 106, 0.3);
        border-right: 1px solid rgba(106, 106, 106, 0.3);
      }
      &:nth-child(3) {
        border-right: 1px solid rgba(106, 106, 106, 0.3);
      }
    }
    &_items {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .gaurantee--container_item {
        margin: 0;
        padding: 0;
        min-height: 68px;
        border: none;
        h3 {
          min-height: 30px;
        }
      }
      @media screen and (max-width: 1250px) {
        padding-left: 35px;
        div {
          &:first-child {
            padding-right: 0;
          }
        }
      }
      @media screen and (max-width: 1102px) {
        padding: 0;
      }
      @media screen and (max-width: 1055px) {
        margin-top: 20px;
      }
    }
    @media screen and (max-width: 1250px) {
      min-width: unset;
      &_item {
        &:nth-child(1) {
          padding-right: 35px;
        }
        &:nth-child(3) {
          padding: 0 35px 0;
        }
      }
    }
    @media screen and (max-width: 1102px) {
      justify-content: center;
      &_item {
        &:nth-child(2) {
          padding: 0 70px;
        }
      }
    }
    @media screen and (max-width: 1055px) {
      &_item {
        &:nth-child(2) {
          padding: 20px 0;
          min-width: 200px;
        }
        &:nth-child(3) {
          border-right: none;
        }
      }
    }
    @media screen and (max-width: 1300px) {
      &_item {
        padding: 20px 0;
        max-width: 200px;
        min-width: 200px;
        &:nth-child(1) {
          padding: 0 0 20px;
        }
        &:nth-child(2) {
          padding: 0 0 20px;
          min-height: 60px;
          border: none;
          border-top: 1px solid rgba(106, 106, 106, 0.3);
        }
        &:nth-child(3) {
          padding: 20px 0;
          border-right: 1px solid #fff;
          border-top: 1px solid rgba(106, 106, 106, 0.3);
          border-bottom: 1px solid rgba(106, 106, 106, 0.3);
        }
      }
      .gaurantee--container_items {
        padding: 20px 0 0;
        .gaurantee--container_item {
          &:nth-child(2) {
            padding: 20px 0;
          }
        }
      }
    }

    @media screen and (max-width: 1300px) {
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      padding: 100px 0 50px;
    }
    @media screen and (max-width: 500px) {
      margin-top: 0;
    }
  }
  @media screen and (max-width: 1250px) {
    height: 100%;
    margin-top: 100px;
  }
}
