.squirlceItem--container {
  padding-top: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 1500px) {
    padding-top: 150px;
  }
  @media screen and (max-width: 1200px) {
    padding-top: 120px;
    h2,
    h3 {
      text-align: center;
    }
  }
}
