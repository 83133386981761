.constructor {
  margin: 0 0 0 260px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &--dicsr {
    .title {
      h3 {
        color: #000;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;

        @media screen and (max-width: 1350px) {
          font-size: 35px;
        }
        @media screen and (max-width: 1250px) {
          font-size: 30px;
        }
        @media screen and (max-width: 768px) {
          font-size: 24px;
        }
      }
      p {
        margin-top: 20px;
        color: #000;
        max-width: 418px;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        @media screen and (max-width: 768px) {
          font-size: 18px;
        }
        @media screen and (max-width: 500px) {
          font-size: 16px;
          margin-top: 15px;
        }
        @media screen and (max-width: 375px) {
          font-size: 14px;
        }
      }
      @media screen and (max-width: 900px) {
        text-align: center;
      }
    }
    .types {
      max-width: 640px;
      display: flex;
      flex-wrap: wrap;
      &--wrapper {
        margin-top: 50px;
        min-width: 320px;
        p {
          min-height: 67px;
          max-width: 250px;
          color: #000;
          font-size: 30px;
          font-style: normal;
          font-weight: 500;
          @media screen and (max-width: 1350px) {
            font-size: 28px;
          }
          @media screen and (max-width: 1250px) {
            font-size: 24px;
            max-width: 150px;
          }
          @media screen and (max-width: 1000px) {
            font-size: 22px;
          }
          @media screen and (max-width: 768px) {
            font-size: 20px;
          }
          @media screen and (max-width: 500px) {
            font-size: 16px;
            margin-top: 15px;
            min-height: 25px;
          }
          @media screen and (max-width: 375px) {
            font-size: 14px;
            max-width: 120px;
          }
        }
        .types--items {
          margin-top: 31px;
          min-width: 230px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .item {
            max-width: 41px;
            margin-right: 37px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            div {
              min-height: 41px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            }
            p {
              margin-top: 7px;
              width: 100%;
              text-align: center;
              color: #000;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              min-width: 100px;
            }
            @media screen and (max-width: 900px) {
              margin: 0;
              &:first-child {
                margin-right: 10px;
              }
              &:last-child {
                margin-left: 10px;
              }
            }
          }
          @media screen and (max-width: 900px) {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          @media screen and (max-width: 559px) {
            margin-top: 15px;
            min-width: 140px;
          }
        }
        @media screen and (max-width: 1200px) {
          min-width: 250px;
        }
        @media screen and (max-width: 900px) {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: nowrap;
          flex-direction: column;
          max-width: 100px;
          min-height: 151px;
          p {
            text-align: center;
          }
        }
        @media screen and (max-width: 560px) {
          min-width: 170px;
          min-height: 90px;
        }
        @media screen and (max-width: 500px) {
          margin-top: 25px;
          &:nth-child(3) {
            margin-top: 10px;
          }
          &:nth-child(4) {
            margin-top: 10px;
          }
        }
        @media screen and (max-width: 400px) {
          min-width: 135px;
        }
      }
      @media screen and (max-width: 900px) {
        justify-content: center;
        align-items: center;
      }
      @media screen and (max-width: 400px) {
        justify-content: space-between;
      }
    }
    @media screen and (max-width: 900px) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
  &--image {
    z-index: -1;
    max-width: 650px;
    max-height: 556px;
    overflow: hidden;
    margin-left: 75px;
    img {
      transform: scale(1.2);
      position: relative;
      bottom: -60px;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    @media screen and (max-width: 1820px) {
      max-width: 600px;
    }
    @media screen and (max-width: 1768px) {
      margin-left: 0;
      max-width: 530px;
    }
    @media screen and (max-width: 1560px) {
      max-width: 550px;
      position: absolute;
      left: 745px;
    }
    @media screen and (max-width: 1400px) {
      left: 650px;
    }
    @media screen and (max-width: 1260px) {
      left: 600px;
      img {
        transform: scale(1.2);
      }
    }
    @media screen and (max-width: 1200px) {
      left: 500px;
    }
    @media screen and (max-width: 1080px) {
      left: 450px;
      img {
        transform: scale(1.1);
      }
    }
    @media screen and (max-width: 1010px) {
      left: 400px;
      img {
        transform: scale(1);
      }
    }
    @media screen and (max-width: 900px) {
      min-width: 640px;
      max-height: 400px;
      position: unset;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        bottom: 90px;
        left: -10px;
      }
    }
    @media screen and (max-width: 665px) {
      min-width: unset;
      max-width: 530px;
    }
    @media screen and (max-width: 565px) {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: unset;
      max-width: 485px;
      img {
        bottom: 50px;
      }
    }
    @media screen and (max-width: 559px) {
      max-height: 300px;
      margin: 20px 0;
      img {
        left: 0;
      }
    }
    @media screen and (max-width: 500px) {
      img {
        transform: scale(1.25);
      }
    }
    @media screen and (max-width: 450px) {
      margin: 0 0 20px;
      img {
        bottom: 0;
      }
    }
    @media screen and (max-width: 385px) {
      img {
        bottom: 0;
      }
    }
  }
  .button {
    position: relative;
    right: 100px;
    bottom: -250px;
    padding: 7px 14px;
    border: none;
    border-radius: 100px;
    background: $titleColor;
    a {
      cursor: pointer;
      color: #000;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      z-index: 1;
    }

    @media screen and (max-width: 1560px) {
      right: -400px;
      bottom: -228px;
    }
    @media screen and (max-width: 1400px) {
      right: -300px;
    }
    @media screen and (max-width: 1260px) {
      right: -200px;
    }
    @media screen and (max-width: 1200px) {
      right: -150px;
    }
    @media screen and (max-width: 1080px) {
      right: -100px;
    }
    @media screen and (max-width: 1010px) {
      right: -70px;
    }
    @media screen and (max-width: 970px) {
      right: 110px;
    }
    @media screen and (max-width: 900px) {
      bottom: 20px;
      right: unset;
    }
    @media screen and (max-width: 665px) {
      bottom: 50px;
    }
    @media screen and (max-width: 450px) {
      bottom: 25px;
    }
  }
  @media screen and (max-width: 1560px) {
    justify-content: flex-start;
  }
  @media screen and (max-width: 1850px) {
    margin: 0 0 0 240px;
  }
  @media screen and (max-width: 1750px) {
    margin: 0 0 0 230px;
  }
  @media screen and (max-width: 1660px) {
    margin: 0 0 0 205px;
  }
  @media screen and (max-width: 1570px) {
    margin: 0 0 0 185px;
  }
  @media screen and (max-width: 1510px) {
    margin: 0 0 0 170px;
  }
  @media screen and (max-width: 1450px) {
    margin: 0 0 0 155px;
  }
  @media screen and (max-width: 1380px) {
    margin: 0 0 0 140px;
  }
  @media screen and (max-width: 1305px) {
    margin: 0 0 0 130px;
  }
  @media screen and (max-width: 1270px) {
    margin: 0 0 0 120px;
  }
  @media screen and (max-width: 1220px) {
    margin: 0 0 0 110px;
  }
  @media screen and (max-width: 1185px) {
    margin: 0 0 0 100px;
  }
  @media screen and (max-width: 1155px) {
    margin: 0 0 0 90px;
  }
  @media screen and (max-width: 1070px) {
    margin: 0 0 0 70px;
  }
  @media screen and (max-width: 1045px) {
    margin: 0 0 0 60px;
  }
  @media screen and (max-width: 1024px) {
    height: 55vh;
  }
  @media screen and (max-width: 900px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0 30px 0;
    .constructor--image {
      order: 2;
    }
    .constructor--dicsr {
      order: 1;
      margin-bottom: 50px;
    }
  }
}
