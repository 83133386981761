.led--container {
  background: $bgDark;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  h2 {
    position: absolute;
    z-index: 14;
    bottom: 185px;
    left: 165px;
    color: #e1e051;
    height: 200px;
    width: 650px;
    font-size: 80px;
    font-style: normal;
    font-weight: 500;
    @media screen and (max-width: 1660px) {
      bottom: 120px;
    }
    @media screen and (max-width: 1550px) {
      bottom: 80px;
    }
    @media screen and (max-width: 1300px) {
      font-size: 60px;
    }
    @media screen and (max-width: 1300px) {
      bottom: 180px;
    }
    @media screen and (max-width: 1024px) {
      font-size: 40px;
      bottom: 60px;
    }
    @media screen and (max-width: 768px) {
      left: 132px;
      font-size: 40px;
      bottom: 2px;
    }
    @media screen and (max-width: 600px) {
      bottom: -35px;
      font-size: 30px;
    }
    @media screen and (max-width:450px) {
      left: 86px;
      
    }
    @media screen and (max-width: 400px) {
      font-size: 25px;
    }
  }
  .led--wrapper {
    background: transparent;
    position: relative;
    left: -250px;
    width: 100%;
    height: 100%;
    .led--description {
      z-index: 3;
      position: absolute;
      top: 68px;
      right: -150px;
      max-width: 400px;
      min-width: 150px;
      &_text {
        border-radius: 80px;
        padding: 7px 20px;
        background: $titleColor;
        cursor: pointer;
        width: 100%;
        p {
          user-select: none;
          text-align: center;
          font-size: 22px;
          width: 100%;
          white-space: nowrap;
          font-style: normal;
          font-weight: 500;
          @media screen and (max-width: 1024px) {
            font-size: 16px;
          }
          @media screen and (max-width: 768px) {
            font-size: 14px;
          }
        }
      }
      &_popup {
        position: absolute;
        right: 0;
        top: 0;
        border-radius: 20px;
        background: $titleColor;
        padding: 10px 15px;
        min-width: 300px;
        ul {
          padding: 0 0 0 10px;
          li {
            margin: 0 0 2px;
            color: $defColor;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            list-style-type: disc;
            p {
              color: rgba(0, 0, 0, 0.5);
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              @media screen and (max-width: 1024px) {
                font-size: 14px;
              }
              @media screen and (max-width: 768px) {
                font-size: 12px;
              }
              span {
                color: $defColor;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
              }
              @media screen and (max-width: 1024px) {
                font-size: 14px;
              }
              @media screen and (max-width: 768px) {
                font-size: 12px;
              }
            }
          }
        }
      }
      @media screen and (max-width: 600px) {
        right: -225px;
      }
    }
    .led--image {
      width: 100%;
      height: 100%;
      img {
        right: -420px;
        top: -770px;
        transform: scale(1.4);
        position: absolute;
        width: 180%;
        object-fit: cover;
        @media screen and (max-width: 1760px) {
          top: -720px;
        }
        @media screen and (max-width: 1660px) {
          top: -670px;
        }
        @media screen and (max-width: 1560px) {
          top: -625px;
        }
        @media screen and (max-width: 1440px) {
          top: -625px;
          transform: scale(1.3);
        }
        @media screen and (max-width: 1366px) and (max-height: 1024px) {
          top: -350px;
          transform: scale(2);
        }
        @media screen and (max-width: 1365px) {
          top: -600px;
        }
        @media screen and (max-width: 1265px) {
          top: -550px;
        }
        @media screen and (max-width: 1180px) {
          transform: scale(1.7);
          top: -400px;
        }
        @media screen and (max-width: 1155px) {
          top: -500px;
        }
        @media screen and (max-width: 1024px) {
          top: -265px;
        }
        @media screen and (max-width: 1024px) and (max-height: 1366px) {
          transform: scale(2);
        }

        @media screen and (max-width: 768px) {
          right: -371px;
          top: -242px;
          transform: scale(1.7);
        }
        @media screen and (max-width:650px) {
          right: -389px;
          top: -206px;
        }
        @media screen and (max-width: 600px) {
          top: -159px;
          right: -325px;
          transform: scale(1.9);
        }
        @media screen and (max-width: 550px) {
          top: -124px;
          right: -338px;
          transform: scale(2);
        }
        @media screen and (max-width: 500px) {
          top: -104px;
          right: -324px;
        }
        @media screen and (max-width: 450px) {
          top: -65px;
          transform: scale(2.2);
        }
        @media screen and (max-width:430px) {
          top: -70px;
        }
        @media screen and (max-width:430px) {
          transform: scale(2.4);
          top: -45px;
        }
      }
    }
  }
  @media screen and (max-width:1024px) {
    height: 700px;
  }
  @media screen and (max-width: 850px) {
    height: 500px;
  }
  @media screen and (max-width: 650px) {
    height: 450px;
  }
  @media screen and (max-width: 500px) {
    height: 430px;
  }
  @media screen and (max-width: 430px) {
    height: 430px;
  }
  @media screen and (max-width: 390px) {
    height: 414px;
  }
}
