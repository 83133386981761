.container {
  height: 100vh;
  width: 100%;
  padding: 213px 0 0 285px;
  &--image {
    overflow: hidden;
    position: absolute;
    height: 100vh;
    top: 0;
    right: 0;
    width: 100%;
    z-index: -1;
    img {
      position: relative;
      left: -665px;
      top: -510px;
      transform: scale(0.65);
      @media screen and (max-width: 1650px) {
        transform: scale(0.55);
        left: -850px;
      }
      @media screen and (max-width: 1480px) {
        transform: scale(0.43);
        left: -1075px;
        top: -700px;
      }
      @media screen and (max-width: 1350px) {
        transform: scale(0.4);
        left: -1150px;
        top: -700px;
      }
      @media screen and (max-width: 1000px) {
        transform: scale(0.35);
        left: -1230px;
        top: -755px;
      }
      @media screen and (max-width: 900px) {
        transform: scale(0.3);
        left: -1330px;
        top: -800px;
      }
      @media screen and (max-width: 768px) {
        transform: scale(0.27);
        left: -1385px;
        top: -835px;
      }
    }
  }
  &--discr {
    p {
      max-width: 430px;
      min-height: 130px;
      color: #000;
      font-size: 38px;
      font-style: normal;
      font-weight: 500;
      margin: 0 0 150px;
      @media screen and (max-width: 1380px) {
        max-width: 380px;
        font-size: 34px;
      }
      @media screen and (max-width: 1000px) {
        font-size: 30px;
        margin: 0 0 75px;
      }
      @media screen and (max-width: 768px) {
        font-size: 24px;
        max-width: 270px;
      }
      @media screen and (max-width: 675px) {
        margin: 0 0 45px;
      }
    }
    @media screen and (max-width: 768px) {
      padding-bottom: 100px;
    }
  }
  @media screen and (max-width: 1850px) {
    padding: 213px 0 0 240px;
  }
  @media screen and (max-width: 1750px) {
    padding: 213px 0 0 230px;
  }
  @media screen and (max-width: 1660px) {
    padding: 213px 0 0 205px;
  }
  @media screen and (max-width: 1570px) {
    padding: 213px 0 0 185px;
  }
  @media screen and (max-width: 1510px) {
    padding: 213px 0 0 170px;
  }
  @media screen and (max-width: 1450px) {
    padding: 213px 0 0 155px;
  }
  @media screen and (max-width: 1380px) {
    padding: 213px 0 0 140px;
  }
  @media screen and (max-width: 1305px) {
    padding: 213px 0 0 130px;
  }
  @media screen and (max-width: 1270px) {
    padding: 213px 0 0 120px;
  }
  @media screen and (max-width: 1220px) {
    padding: 213px 0 0 110px;
  }
  @media screen and (max-width: 1185px) {
    padding: 213px 0 0 100px;
  }
  @media screen and (max-width: 1155px) {
    padding: 213px 0 0 90px;
  }
  @media screen and (max-width: 1070px) {
    padding: 213px 0 0 70px;
  }
  @media screen and (max-width: 1045px) {
    padding: 213px 0 0 60px;
  }
  @media screen and (max-width:1025px) {
    height: 80vh;
  }
  @media screen and (max-width: 768px) {
    padding: 180px 0 0 60px;
    height: 100%;
  }
  @media screen and (max-width: 675px) {
    padding: 180px 0 0 35px;
  }
}
