.ground-container {
  background: rgba(217, 217, 217, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 46px;
  h2 {
    float: left;
    width: 100%;
    color: #000;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    padding: 30px 0 20px 150px;
    text-transform: uppercase;
    @media screen and (max-width: 1365px) {
      padding: 30px 30px 20px;
    }
    @media screen and (max-width: 450px) {
      font-size: 30px;
    }
  }
  &_wrapper {
    max-width: 1070px;
    flex-wrap: wrap;
    margin-top: 30px;
    display: flex;
    align-items: flex-start;

    a {
      margin-bottom: 20px;
      background: #fff;
      margin-right: 10px;
      max-width: 300px;
      height: 290px;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      overflow: hidden;
      border-radius: 23px;
      .ground_item {
        overflow: hidden;
        border-radius: 23px;
        max-width: 300px;
        height: 290px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        div {
         align-self: center;
          img {
            user-select: none;
            border-radius: 23px;
            width: 100%;
            height: 100%;
            object-fit: contain;
            transition: transform 0.3s ease-in-out;
            &:hover {
              transform: scale(1.1);
              transition: transform 0.3s ease-in-out;
            }
          }
        }
        h3 {
          z-index: 1;
          color: #000;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          padding: 0 0 5px 15px;
          @media screen and (max-width: 450px) {
            font-size: 16px;
          }
        }
        p {
          z-index: 1;
          color: rgba(0, 0, 0, 0.5);
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          padding: 0 0 15px 15px;
          @media screen and (max-width: 450px) {
            font-size: 14px;
          }
        }
      }
      &:first-child {
        max-width: 200px;
        margin-right: 10px;
        @media screen and (max-width: 560px) {
          margin-right: 0;
          margin: 0;
          max-width: 150px;
          div {
            overflow: hidden;
            img {
              transform: scale(1.2);
              position: relative;
              &:hover {
                transform: scale(1.4);
              }
            }
          }
        }
        @media screen and (max-width: 400px) {
          max-width: 115px;
        }
      }
      &:last-child {
        margin-right: 0;
        margin-left: 10px;
        min-width: 520px;
        .ground_item {
          max-width: 400px;
          overflow: unset;
          div {
            width: 400px;
            img {
              position: relative;
              bottom: -90px;
              left: 40px;
            }
          }
        }
        @media screen and (max-width: 1120px) {
          margin-left: 0;
        }
        @media screen and (max-width: 560px) {
          min-width: 370px;
          max-width: 370px;
          margin-left: 0;
          margin: 0;
          .ground_item {
            min-width: 370px;
            max-width: 370px;
            overflow: hidden;
            div {
              img {
                transform: scale(0.7);
                left: 0;
              }
            }
          }
        }
        @media screen and (max-width: 400px) {
          max-width: 340px;
          min-width: 340px;
          .ground_item {
            max-width: 340px;
            min-width: 340px;
          }
        }
      }
      &:nth-child(2) {
        margin-left: 10px;
        max-width: 305px;
        .ground_item {
          div {
            position: relative;
            bottom: -40px;
            @media screen and (max-width: 560px) {
              bottom: -15px;
            }
            img {
              @media screen and (max-width: 560px) {
                transform: scale(1.15);
              }
            }
          }
        }
        @media screen and (max-width: 560px) {
          margin-left: 0;
          margin: 0 0 0 10px;
          max-width: 200px;
        }
        @media screen and (max-width: 400px) {
          max-width: 215px;
          div {
            img {
              position: relative;
              bottom: -10px;
            }
          }
        }
      }
      &:nth-child(3) {
        margin-left: 10px;
        max-width: 200px;
        @media screen and (max-width: 1120px) {
          margin-left: 0;
        }
        @media screen and (max-width: 560px) {
          margin: 10px 0 0;
          max-width: 150px;
          div {
            overflow: hidden;
            img {
              transform: scale(1.2);
              position: relative;
              &:hover {
                transform: scale(1.4);
              }
            }
          }
        }
        @media screen and (max-width: 400px) {
          max-width: 115px;
        }
      }
      &:nth-child(4) {
        margin-left: 10px;
        margin-right: 0;
        max-width: 300px;
        .ground_item {
          div {
            overflow: hidden;
            img {
              transform: scale(1.3);
              position: relative;
              bottom: -10px;
              transition: transform 0.3s ease-in-out;
              &:hover {
                transform: scale(1.4);
                transition: transform 0.3s ease-in-out;
              }
            }
          }
        }
        @media screen and (max-width: 1120px) {
          margin-left: 20px;
        }
        @media screen and (max-width: 560px) {
          margin-left: 10px;
          margin-top: 10px;
          max-width: 200px;
          margin-bottom: 0;
        }
        @media screen and (max-width: 400px) {
          max-width: 215px;
          .ground_item {
            div {
              img {
                transform: scale(1.2);
                bottom: -20px;
              }
            }
          }
        }
      }
      &:nth-child(5) {
        max-width: 305px;
        .ground_item {
          div {
            img {
              position: relative;
              top: 35px;
            }
          }
        }
        @media screen and (max-width: 1120px) {
          margin-left: 0;
        }
        @media screen and (max-width: 560px) {
          margin: 10px 0 10px;
          max-width: 150px;
          .ground_item {
            div {
              img {
                top: -7px;
              }
            }
          }
        }
        @media screen and (max-width: 400px) {
          max-width: 115px;
        }
      }
      &:nth-child(6) {
        margin-left: 10px;
        max-width: 200px;
        @media screen and (max-width: 1120px) {
          margin-left: 20px;
        }
        @media screen and (max-width: 560px) {
          margin-left: 0;
          margin: 10px 0 10px 10px;
          max-width: 200px;
          div {
            overflow: hidden;
            img {
              transform: scale(1.2);
              position: relative;
              bottom: -10px;
              &:hover {
                transform: scale(1.4);
              }
            }
          }
        }
        @media screen and (max-width: 400px) {
          max-width: 215px;
          div {
            img {
              bottom: -20px;
            }
          }
        }
      }
      @media screen and (max-width: 1120px) {
        margin-right: 0;
      }
      @media screen and (max-width: 560px) {
        height: 250px;
      }
      @media screen and (max-width: 400px) {
        max-width: 115px;
        height: 220px;
      }
    }
    @media screen and (max-width: 1120px) {
      max-width: 530px;
      align-items: center;
      justify-content: flex-start;
    }
    @media screen and (max-width: 560px) {
      max-width: 370px;
      justify-content: center;
    }
    @media screen and (max-width: 400px) {
      max-width: 350px;
    }
  }
}
