.mound-container {
  background: rgba(217, 217, 217, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 46px;
  h2 {
    float: left;
    width: 100%;
    color: #000;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    padding: 30px 0 20px 150px;
    text-transform: uppercase;
    @media screen and (max-width: 1365px) {
      padding: 30px 30px 20px;
    }
    @media screen and (max-width: 450px) {
      font-size: 30px;
    }
  }
  &_wrapper {
    min-width: 1070px;
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    a {
      background: #fff;
      margin-right: 10px;
      max-width: 300px;
      height: 290px;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      overflow: hidden;
      border-radius: 23px;
      .mound_item {
        border-radius: 23px;
        max-width: 300px;
        height: 290px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        div {
          img {
            border-radius: 23px;
            width: 100%;
            height: 100%;
            object-fit: contain;
            transition: transform 0.3s ease-in-out;
            &:hover {
              transform: scale(1.1);
              transition: transform 0.3s ease-in-out;
            }
          }
        }
        h3 {
          z-index: 1;
          color: #000;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          padding: 0 0 5px 15px;
          @media screen and (max-width: 450px) {
            font-size: 16px;
          }
        }
        p {
          color: rgba(0, 0, 0, 0.5);
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          padding: 0 0 15px 15px;
          @media screen and (max-width: 450px) {
            font-size: 14px;
          }
        }
        @media screen and (max-width: 560px) {
          max-width: 200px;
        }
      }
      &:first-child,
      &:last-child {
        max-width: 200px;
      }
      &:first-child {
        margin-right: 10px;
        @media screen and (max-width: 560px) {
          max-width: 150px;
          div {
            overflow: hidden;
            img {
              transform: scale(1.4);
              position: relative;
              left: 0;
              top: -20px
            }
          }
        }
        @media screen and (max-width: 450px) {
          div {
            img {
              transform: scale(1.2);
            }
          }
        }
        @media screen and (max-width: 400px) {
          max-width: 115px;
        }
      }
      &:last-child {
        margin-left: 10px;
        @media screen and (max-width: 1120px) {
          margin-left: 0;
          margin: 0 0 0 10px;
        }
        @media screen and (max-width: 560px) {
          max-width: 150px;
          margin: 0;
          div {
            overflow: hidden;
            img {
              transform: scale(1.4);
              position: relative;
              left: 0;
              top: -20px
            }
          }
        }
        @media screen and (max-width: 450px) {
          div {
            img {
              transform: scale(1.2);
              left: -5px;
          
            }
          }
        }
        @media screen and (max-width: 400px) {
          max-width: 115px;
        }
      }
      &:nth-child(2) {
        display: none;
        margin-left: 10px;
        .mound_item {
          div {
            position: relative;
            bottom: -20px;
          }
        }
        @media screen and (max-width: 1120px) {
          margin-right: 0;
        }
        @media screen and (max-width: 560px) {
          margin-left: 0;
        }
      }
      &:nth-child(3) {
        display: none;
        margin-left: 10px;
        .mound_item {
          div {
            position: relative;
            bottom: -20px;
          }
        }
        @media screen and (max-width: 1120px) {
          margin-left: 0;
          margin: 10px 0 0;
        }
        @media screen and (max-width: 560px) {
          margin: 10px 0 0;
        }
      }
      @media screen and (max-width: 560px) {
        height: 250px;
      }
    }
    @media screen and (max-width: 1120px) {
      min-width: 530px;
      align-items: center;
      justify-content: flex-start;
    }
    @media screen and (max-width: 560px) {
      min-width: 370px;
      justify-content: flex-start;
    }
    @media screen and (max-width: 400px) {
      min-width: 350px;
    }
  }
}
