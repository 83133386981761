.politics_container {
  padding: 140px 20px 50px;
  h2 {
    font-size: 50px;
    margin-bottom: 20px;
    
    @media screen and (max-width: 1250px) {
      font-size: 40px;
    }
    @media screen and (max-width: 1000px) {
      font-size: 35px;
    }
    @media screen and (max-width: 768px) {
      font-size: 30px;
      width: 100%;
    }
  }
  h4 {
    font-size: 24px;
  }
  p {
    margin: 15px 0;
  }
}
