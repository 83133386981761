.sets-container {
  background: rgba(217, 217, 217, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 146px;
  h2 {
    float: left;
    width: 100%;
    color: #000;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    padding: 30px 0 20px 150px;
    text-transform: uppercase;
    @media screen and (max-width: 1365px) {
      padding: 30px 30px 20px;
    }
    @media screen and (max-width: 450px) {
      font-size: 30px;
    }
  }
  &_wrapper {
    max-width: 1070px;
    min-width: 1070px;
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    a {
      border-radius: 23px;
      overflow: hidden;
      .sets_item {
        max-width: 220px;
        min-height: 290px;
        position: relative;
        border-radius: 23px;
        overflow: hidden;
        background: #fff;
        img {
          border-radius: 23px;
          position: relative;
          top: -20px;
          width: 100%;
          height: 300px;
          object-fit: contain;
          transition: transform 0.3s ease-in-out;
          &:hover {
            transform: scale(1.1);
            transition: transform 0.3s ease-in-out;
          }
        }
        .sets-text {
          position: absolute;
          bottom: 7px;
          left: 0px;
          h3 {
            z-index: 1;
            color: #000;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            padding: 0 0 5px 15px;
            @media screen and (max-width: 450px) {
              font-size:16px;
            }
          }
          p {
            color: rgba(0, 0, 0, 0.5);
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            padding: 0 0 15px 15px;
            @media screen and (max-width: 450px) {
              font-size: 14px;
            }
          }
        }
      }
      &:nth-child(1) {
        .sets_item {
          img {
            transform: scale(1.4);
            transition: transform 0.3s ease-in-out;
            &:hover {
              transform: scale(1.5);
              transition: transform 0.3s ease-in-out;
            }
          }
          @media screen and (max-width: 1120px) {
            min-width: 530px;
            max-width: 530px;
          }
          @media screen and (max-width: 560px) {
            min-width: 370px;
            max-width: 370px;
            overflow: hidden;
          }
          @media screen and (max-width: 400px) {
            max-width: 340px;
            min-width: 340px;
          }
        }
      }
      &:nth-child(2) {
        .sets_item {
          margin: 0 15px;
          min-width: 450px;
          max-width: 450px;
          @media screen and (max-width: 1120px) {
            margin: 20px 0;
            min-width: 530px;
            max-width: 530px;
          }
          @media screen and (max-width: 560px) {
            min-width: 370px;
            max-width: 370px;
            overflow: hidden;
          }
          @media screen and (max-width: 400px) {
            max-width: 340px;
            min-width: 340px;
          }
        }
      }
      &:nth-child(3) {
        .sets_item {
          min-width: 370px;
          max-width: 370px;
          img {
            transform: scale(1);
            transition: transform 0.3s ease-in-out;
            &:hover {
              transform: scale(1.1);
              transition: transform 0.3s ease-in-out;
            }
          }
          @media screen and (max-width: 1120px) {
            min-width: 530px;
            max-width: 530px;
          }
          @media screen and (max-width: 560px) {
            min-width: 370px;
            max-width: 370px;
            overflow: hidden;
          } 
          @media screen and (max-width: 400px) {
            max-width: 340px;
            min-width: 340px;
          }
        }
      }
      @media screen and (max-width: 560px) {
        min-width: 370px;
      }
      @media screen and (max-width: 400px) {
        min-width: 340px;
      }
    }
    @media screen and (max-width: 1120px) {
      min-width: 530px;
      max-width: 530px;
      align-items: center;
      justify-content: center;
    }
    @media screen and (max-width: 470px) {
      min-width: 300px;
      max-width: 350px;
    }
  }
}
