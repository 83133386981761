header {
  position: absolute;
  padding: 21px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-height: 92px;
  background: transparent;
  width: 100%;
  z-index: 10000;
  .header--logo {
    max-width: 300px;
    min-width: 300px;
    min-height: 77px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      display: block;
      width: 100%;
      height: 100%;
      min-height: 77px;
      cursor: pointer;
      transform: scale(1.03);
      transition: transform 0.5s ease-in-out;
      background: url("../../assets/images/logo_tramptec.png") center center /
        contain no-repeat;
      &:hover {
        transition: transform 0.5s ease-in-out;
        background: url("../../assets/images/giflogo.gif") center center / cover
          no-repeat;
        transform: scale(1);
      }
    }
    @media screen and (max-width: 435px) {
      min-width: 230px;
    }
  }
  nav {
    display: flex;
    align-items: center;
    justify-content: center;
    .nav--items {
      display: flex;
      align-items: center;
      justify-content: center;
      li {
        user-select: none;
        min-width: 100px;
        text-align: center;
        margin: 0 20px;
        a {
          cursor: pointer;
          color: $defColor;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: uppercase;
          transition: color 0.3s ease-in-out;
          transition: fill 0.3s ease-in-out;
          &:hover {
            color: rgba(0, 0, 0, 0.51);
            transition: color 0.3s ease-in-out;
          }
        }
        .language {
          position: relative;
          &--select {
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
              p {
                color: rgba(0, 0, 0, 0.51);
                transition: color 0.3s ease-in-out;
              }
              .language--select__arrow {
                svg {
                  path {
                    fill: rgba(0, 0, 0, 0.51);
                    transition: fill 0.3s ease-in-out;
                  }
                }
              }
            }
            &__arrow {
              cursor: pointer;
              margin-left: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: transform 0.4s ease-in-out;
              transition: fill 0.3s ease-in-out;
            }
            &__arrow-active {
              transform: rotate(-180deg);
              margin-left: 5px;
              display: flex;
              align-items: flex-start;
              justify-content: center;
              transition: transform 0.4s ease-in-out;
            }
            p {
              cursor: pointer;
              text-transform: uppercase;
              color: $defColor;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }
          &--items {
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-direction: column;
            position: absolute;
            min-width: 75px;
            padding: 5px 0;
            min-height: 165px;
            left: 5px;
            top: 25px;
            background: $bgBrigth;
            border-radius: 15px;
            border: 1px solid #000;

            .language--item {
              display: flex;
              align-items: center;
              justify-content: center;
              span {
                cursor: pointer;
                display: block;
                text-transform: uppercase;
                min-height: 21px;
                text-align: left;
                transition: color 0.3s ease-in-out;
                margin-top: 5px;
                &:hover {
                  color: rgba(0, 0, 0, 0.51);
                  transition: color 0.3s ease-in-out;
                }
              }
            }
          }
        }
        &:last-child {
          margin: 0 20px 0 0;
        }
      }
      .download {
        cursor: pointer;
        border-radius: 100px;
        background: $titleColor;
        max-width: 150px;
        height: 29px;
        padding: 14px 40px;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-shrink: 0;
        text-transform: uppercase;
        font-weight: 500;
        transition: background 0.3s ease-in-out;
        &:hover {
          background: #f3f3b9;
          transition: background 0.3s ease-in-out;
        }
      }
      @media screen and (max-width: 900px) {
        display: none;
      }
    }
    .burger--menu {
      display: none;
      @media screen and (max-width: 900px) {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 30px;
        .burger-language {
          position: relative;
          .language--select {
            display: flex;
            align-items: center;
            justify-content: center;

            &__arrow {
              margin-left: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: transform 0.4s ease-in-out;
            }
            &__arrow-active {
              transform: rotate(-180deg);
              margin-left: 5px;
              display: flex;
              align-items: flex-start;
              justify-content: center;
              transition: transform 0.4s ease-in-out;
            }
            p {
              cursor: pointer;
              text-transform: uppercase;
              color: $defColor;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              transition: color 0.3s ease-in-out;
              &:hover {
                color: rgba(0, 0, 0, 0.51);
                transition: color 0.3s ease-in-out;
              }
            }
          }
          &_items {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            position: absolute;
            z-index: 10;
            min-width: 55px;
            min-height: 50px;
            left: -12px;
            top: 30px;
            background: $bgBrigth;
            border-radius: 8px;
            border: 1px solid #000;

            .burger-language--item {
              &.selected {
                display: none;
              }
             display: flex;
             justify-content: center;
             flex-direction: column;
             margin: 10px 7px;
              span {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                text-transform: uppercase;
                min-height: 21px;
                min-width: 33px;
                font-size: 14px;
                text-align: left;
                transition: color 0.3s ease-in-out;
                &:hover {
                  color: rgba(0, 0, 0, 0.51);
                  transition: color 0.3s ease-in-out;
                }
              }
            }
          }
        }
        .burger--button {
          display: flex;
          align-items: center;
          justify-content: space-around;
          border-radius: 2px;
          min-width: 20px;
          min-height: 20px;
          margin-left: 20px;
          padding: 0 2px 0;
        }
        .burger--container {
          min-width: 90px;
          position: absolute;
          top: -11px;
          background: rgb(225, 224, 81);
          display: flex;
          align-items: flex-end;
          justify-content: center;
          flex-direction: column;
          .exit--container {
            position: relative;
            border-radius: 2px;
            max-width: 20px;
            min-width: 20px;
            min-height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 12px 10px 10px 10px;
            svg {
              position: absolute;
            }
          }
          .burger--items {
            margin: 10px;
            li {
              margin: 0 0 20px;
              color: #000;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              a {
                color: #000;
              }
            }
          }
        }
      }
      @media screen and (max-width: 430px) {
        .burger--button {
          margin-left:25px;
        }
      }
    }
  }
  @media screen and (max-width: 900px) {
    justify-content: space-between;
  }
}
.sticky {
  position: fixed;
  animation: stickyHeader 3s forwards;
  background: #fff;
}
@keyframes stickyHeader {
  0% {
    transform: translateY(-100px);
  }
  20% {
    transform: translateY(0);
  }
}
