.businessCard_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .businessCard_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &-logo {
      width: 100%;
      height: 100px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transform: scale(2);
      }
    }
    &-description {
      margin: 10px 0 0;
      text-align: center;
      h2 {
        font-size: 28px;
      }
      p {
        font-size: 24px;
        font-weight: 400;
        max-width: 300px;
      }
    }
    &-contacts {
      margin-top: 28px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      a {
        font-size: 20px;
        text-align: center;
        padding: 12px 0;
        width: 300px;
        color: #000;
        border-radius: 25px;
        transition: color 0.3s ease-in-out;
        background: transparent;
        border: 2px solid #000;
        transition: border 0.3s ease-in-out;
        &:hover {
          transition: color 0.3s ease-in-out;
          color: #818181;
          transition: border 0.3s ease-in-out;
          border: 2px solid #818181;
        }
        &:last-child {
          margin-top: 12px;
        }
      }
    }
    &-links {
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 300px;
      a {
        width: 144px;
        font-size: 20px;
        text-align: center;
        padding: 12px 0;
        color: #000;
        border-radius: 25px;
        transition: color 0.3s ease-in-out;
        background: transparent;
        border: 2px solid #000;
        transition: border 0.3s ease-in-out;
        &:hover {
          transition: color 0.3s ease-in-out;
          color: #818181;
          transition: border 0.3s ease-in-out;
          border: 2px solid #818181;
        }
      }
    }
    &-add {
        cursor: pointer;
      margin-top: 40px;
      min-width: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      #addToContact {
        width: 100%;
        min-height: 52px;
        border-radius: 25px;
        background: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        button {
          background: transparent;
          outline: none;
          border: none;
          color: #fff;
          font-size: 20px;
          cursor: pointer;
        }
        img {
          margin-right: 10px;
        }
      }
      a {
        margin-top: 12px;
        width: 300px;
        font-size: 20px;
        text-align: center;
        padding: 12px 0;
        color: #000;
        border-radius: 25px;
        transition: color 0.3s ease-in-out;
        background: #e1e051;
        border: 2px solid #e1e051;
        transition: border 0.3s ease-in-out;
        &:hover {
          transition: background 0.3s ease-in-out;
          background: transparent;
        }
      }
    }
    &-code {
      margin: 60px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      .code-card {
        max-width: 300px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
