.environment-container {
  margin: 140px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &_logo {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100px;
    max-height: 66px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &_text {
    width: 100%;  
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h2 {
      text-align: center;
      color: $titleColor;
      font-size: 86px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      @media screen and (max-width: 1350px) {
        font-size: 70px;
      }
      @media screen and (max-width: 1250px) {
        font-size: 60px;
      }
      @media screen and (max-width: 1000px) {
        font-size: 50px;
      }
      @media screen and (max-width: 768px) {
        font-size: 45px;
      }
      @media screen and (max-width: 500px) {
        font-size: 40px;
      }
      @media screen and (max-width: 375px) {
        font-size: 30px;
      }
    }
    p {
      margin-top: 15px;
      text-align: center;
      color: $defColor;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      max-width: 700px;
      @media screen and (max-width: 1350px) {
        font-size: 28px;
      }
      @media screen and (max-width: 1250px) {
        font-size: 24px;
      }
      @media screen and (max-width: 1000px) {
        font-size: 22px;
      }
      @media screen and (max-width: 768px) {
        font-size: 20px;
      }
      @media screen and (max-width: 500px) {
        font-size: 16px;
        margin-top: 15px;
      }
      @media screen and (max-width: 375px) {
        font-size: 14px;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    margin: 40px 10px 100px;
  }
}
