.squirlceItem--container_wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 50px;
  min-width: 1077px;
  .squirlce--image {
    overflow: hidden;
    max-width: 520px;
    img {
      width: 100%;
      object-fit: contain;
      position: relative;
      left: -30px;
      top: -20px;
      @media screen and (max-width: 1200px) {
        left: unset;
      }
    }
    @media screen and (max-width: 1200px) {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: unset;
      max-width: 560px;
      position: absolute;
      top: 60px;
      z-index: -1;
    }
    @media screen and (max-width: 500px) {
      margin-top: 40px;
    }
  }
  .squirlce--constructor {
    color: #000;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    h2 {
      margin: 0 0 20px;
      @media screen and (max-width: 450px) {
        font-size: 30px;
      }
    }
    @media screen and (max-width: 450px) {
     h3 {
      font-size: 28px;
     }
    }

    &_items {
      position: relative;
      margin-top: 43px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .squircle_item {
        max-width: 400px;
        min-width: 400px;
        min-height: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .items--description {
          p {
            max-width: 130px;
            min-height: 45px;
            color: #000;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            @media screen and (max-width: 1200px) {
              text-align: center;
            }
          }
        }
        .items--types {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .item {
            margin-left: 25px;
            min-width: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .circle {
              cursor: pointer;
              width: 25px;
              height: 25px;
              border-radius: 50%;
              background: #d9d9d9;
            }
            .circle.active {
              background: #e1e051;
            }
            p {
              margin-top: 7px;
              text-align: center;
              min-width: 47px;
              color: #000;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
            }
            @media screen and (max-width: 1200px) {
              margin: 0;
            }
          }
          @media screen and (max-width: 1200px) {
            margin: 15px 0 10px;
          }
        }
        @media screen and (max-width: 1200px) {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          min-width: 130px;
          margin: 20px 0;
          &:nth-child(2) {
            margin: 20px 5px 20px 10px;
          }
          &:nth-child(3) {
            margin: 20px 10px 20px 5px;
          }
        }
        @media screen and (max-width: 600px) {
          &:nth-child(3) {
            margin: 0 10px 20px 5px;
          }
          &:nth-child(4) {
            margin: 0 0 20px;
          }
        }
      }
      .button {
        margin: 20px 0 10px;
        @media screen and (max-width: 550px) {
          position: absolute;
          bottom: -70px;
        }
      }
      @media screen and (max-width: 1200px) {
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 560px;
        margin-top: 400px;
        margin-bottom: 50px;
      }
      @media screen and (max-width: 550px) {
        margin-top: 345px;
        margin-bottom: 100px;
      }
      @media screen and (max-width: 450px) {
        max-width: 414px;
        margin-top: 300px;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    min-width: unset;
    position: relative;
  }
}
