.spring--container {
  background: $bgBrigth;
  margin: 30px 30px 60px;

  &__wrapper {
    background: $titleColor;
    border-radius: 38px;
    position: relative;
    width: 100%;
    .spring--image {
      position: relative;
      border-radius: 38px;
      top: 4px;
      max-height: 850px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-bottom-left-radius: 38px;
        border-bottom-right-radius: 38px;
      }
      @media screen and (max-width: 490px) {
        border-radius: 38px;
        background: url("../../assets/images/springsDf.webp") center bottom /
          contain no-repeat;
        width: 100%;
        height: 250px;
        img {
          display: none;
        }
      }
      @media screen and (max-width: 1600px) {
        max-height: 750px;
      }
      @media screen and (max-width: 1500px) {
        max-height: 650px;
      }
    }
    .description {
      width: 100%;
      position: absolute;
      bottom: 25px;
      left: 28px;
      h3 {
        color: $titleColor;
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        @media screen and (max-width: 1300px) {
          font-size: 44px;
        }
        @media screen and (max-width: 1024px) {
          font-size: 36px;
        }
        @media screen and (max-width: 870px) {
          font-size: 32px;
        }
        @media screen and (max-width: 770px) {
          font-size: 28px;
        }
        @media screen and (max-width: 700px) {
          font-size: 26px;
        }
        @media screen and (max-width: 650px) {
          font-size: 24px;
        }
      }
      p {
        margin-top: 15px;
        color: $bgBrigth;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        max-width: 990px;
        @media screen and (max-width: 1500px) {
          max-width: 965px;
        }
        @media screen and (max-width: 1200px) {
          font-size: 16px;
        }
        @media screen and (max-width: 870px) {
          font-size: 14px;
          max-width: 400px;
        }
        @media screen and (max-width: 490px) {
          color: rgba(0, 0, 0, 0.5);
        }
      }
      @media screen and (max-width: 1765px) {
        bottom: 50px;
      }
      @media screen and (max-width: 770px) {
        bottom: 25px;
      }
      @media screen and (max-width: 540px) {
        left: 10px;
      }
      @media screen and (max-width: 490px) {
        top: -180px;
        left: 0;
        h3 {
          color: $defColor;
        }
        p {
          max-width: 335px;
        }
      }
    }
    @media screen and (max-width: 490px) {
      margin-top: 230px;
    }
  }
}
