.membranes--container {
  margin-top: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 30px;
  h2 {
    color: $defColor;
    text-align: center;
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    @media screen and (max-width: 1350px) {
      font-size: 40px;
    }
    @media screen and (max-width: 1250px) {
      font-size: 30px;
    }
    @media screen and (max-width: 768px) {
      font-size: 24px;
    }
  }
  .membranes--wrappper {
    margin-top: 40px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    h3 {
      color: $defColor;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      @media screen and (max-width: 1350px) {
        font-size: 25px;
      }
      @media screen and (max-width: 1250px) {
        font-size: 20px;
      }
      @media screen and (max-width: 855px) {
        margin-bottom: 10px;
      }
      @media screen and (max-width: 768px) {
        font-size: 18px;
      }
    }
    p {
      color: $defColor;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      max-width: 590px;
      @media screen and (max-width: 1350px) {
        font-size: 16px;
      }
      @media screen and (max-width: 855px) {
        max-width:360px;
      }
      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
      &:first-child,
      &:nth-child(2) {
        min-height:85px;
        @media screen and (max-width: 1300px) {
          min-height: 65px;
        }
        @media screen and (max-width: 855px) {
          min-height: unset;
        }
      }
      &:last-child,
      &:nth-child(3) {
        margin-top: 10px;
      }
    }
    .rubber {
      display: flex;
      flex-direction: column;
      &--image {
        align-self: center;
        position: relative;
        left: -45px;
        max-width: 400px;
        max-height: 450px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        img {
          transform: scale(1.5);
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .svg--custom {
          z-index: 10;
          position: absolute;
          left: 50px;
          bottom: 100px;
          p {
            user-select: none;
            font-size: 12px;
            position: relative;
            max-width: 75px;
            min-height: unset;
            bottom: -54px;
            left: 5px;
            text-align: center;
          }
          @media screen and (max-width: 500px) {
            left: 185px;
            bottom: 30px;
          }
          @media screen and (max-width: 435px) {
            left: 165px;
            bottom: 40px;
          }
          @media screen and (max-width: 400px) {
            left: 145px;
            bottom: 40px;
          }
        }
        @media screen and (max-width: 855px) {
          left: -15px;
        }
      }
    }
    .fabric {
      display: flex;
      flex-direction: column;
      margin-left: 50px;
      &--image {
        align-self: center;
        overflow: hidden;
        position: relative;
        left: -45px;
        max-width: 400px;
        max-height: 450px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          transform: scale(1.4);
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        @media screen and (max-width: 855px) {
          left: -15px;
        }
      }
      @media screen and (max-width: 1300px) {
        margin-left: 0;
      }
    }
    @media screen and (max-width: 1300px) {
      flex-direction: column;

    }
    @media screen and (max-width: 490px) {
      margin-top: 25px;
    }
  }
}
