.quality--container {
  position: relative;
  margin-bottom: 100px;

  .quality--texts {
    width: 100%;
    &_item-text {
      height: 100vh;
      p {
        padding: 40px 0 0 260px;
        max-width: 1186px;
        color: rgba(0, 0, 0, 0.5);
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
        animation: textInBlock1 3s forwards;
        @media screen and (max-width: 1570px) {
          max-width: 1100px;
        }
        @media screen and (max-width: 1380px) {
          font-size: 34px;
        }
        @media screen and (max-width: 1000px) {
          font-size: 30px;
        }
        @media screen and (max-width: 768px) {
          font-size: 24px;
        }
        @media screen and (max-width: 600px) {
          font-size: 20px;
          line-height: normal;
        }
        span {
          color: #000;
          font-size: 40px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          @media screen and (max-width: 1380px) {
            font-size: 34px;
          }
          @media screen and (max-width: 1000px) {
            font-size: 30px;
          }
          @media screen and (max-width: 768px) {
            font-size: 24px;
          }
          @media screen and (max-width: 600px) {
            font-size: 20px;
            line-height: normal;
          }
        }
        @media screen and (max-width: 1850px) {
          padding: 140px 0 0 240px;
        }
        @media screen and (max-width: 1750px) {
          padding: 140px 0 0 230px;
        }
        @media screen and (max-width: 1660px) {
          padding: 140px 0 0 205px;
        }
        @media screen and (max-width: 1570px) {
          padding: 40px 0 0 185px;
        }
        @media screen and (max-width: 1510px) {
          padding: 40px 0 0 170px;
        }
        @media screen and (max-width: 1450px) {
          padding: 40px 0 0 155px;
        }
        @media screen and (max-width: 1380px) {
          padding: 40px 0 0 140px;
        }
        @media screen and (max-width: 1305px) {
          padding: 40px 0 0 130px;
        }
        @media screen and (max-width: 1270px) {
          padding: 40px 0 0 120px;
        }
        @media screen and (max-width: 1220px) {
          padding: 40px 0 0 110px;
        }
        @media screen and (max-width: 1185px) {
          padding: 40px 100px 0 100px;
        }
        @media screen and (max-width: 1155px) {
          padding: 40px 90px 0 90px;
        }
        @media screen and (max-width: 1070px) {
          padding: 40px 70px 0 70px;
        }
        @media screen and (max-width: 1045px) {
          padding: 40px 30px 0;
        }
      }
    }
    &_item-text2 {
      height: 100vh;
      background: black;
      p {
        opacity: 0;
        transform: translateY(20vh);
        padding: 40px 0 0 260px;
        max-width: 1165px;
        color: #e1e051;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
        @media screen and (max-width: 1570px) {
          max-width: 1100px;
        }
        @media screen and (max-width: 1380px) {
          font-size: 34px;
        }
        @media screen and (max-width: 1000px) {
          font-size: 30px;
        }
        @media screen and (max-width: 768px) {
          font-size: 24px;
        }
        @media screen and (max-width: 600px) {
          font-size: 20px;
          line-height: normal;
        }
        span {
          color: rgba(225, 224, 81, 0.54);
          font-size: 40px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          @media screen and (max-width: 1380px) {
            font-size: 34px;
          }
          @media screen and (max-width: 1000px) {
            font-size: 30px;
          }
          @media screen and (max-width: 768px) {
            font-size: 24px;
          }
          @media screen and (max-width: 600px) {
            font-size: 20px;
            line-height: normal;
          }
        }
        @media screen and (max-width: 1850px) {
          padding: 140px 0 0 240px;
        }
        @media screen and (max-width: 1750px) {
          padding: 140px 0 0 230px;
        }
        @media screen and (max-width: 1660px) {
          padding: 140px 0 0 205px;
        }
        @media screen and (max-width: 1570px) {
          padding: 40px 0 0 185px;
        }
        @media screen and (max-width: 1510px) {
          padding: 40px 0 0 170px;
        }
        @media screen and (max-width: 1450px) {
          padding: 40px 0 0 155px;
        }
        @media screen and (max-width: 1380px) {
          padding: 40px 0 0 140px;
        }
        @media screen and (max-width: 1305px) {
          padding: 40px 0 0 130px;
        }
        @media screen and (max-width: 1270px) {
          padding: 40px 0 0 120px;
        }
        @media screen and (max-width: 1220px) {
          padding: 40px 0 0 110px;
        }
        @media screen and (max-width: 1185px) {
          padding: 40px 100px 0 100px;
        }
        @media screen and (max-width: 1155px) {
          padding: 40px 90px 0 90px;
        }
        @media screen and (max-width: 1070px) {
          padding: 40px 70px 0 70px;
        }
        @media screen and (max-width: 1045px) {
          padding: 40px 30px 0;
        }
      }
    }
    &_item-text3 {
      height: 150vh;
      background: gray;
      display: flex;
      justify-content: center;

      &.active {

        @media screen and (max-width: 1600px) {
          padding-bottom: 65vh;
        }
        @media screen and (max-width: 1500px) {
          padding-bottom: 63vh;
        }
        @media screen and (max-width: 1440px) {
          padding-bottom: 61vh;
        }
        @media screen and (max-width: 1400px) {
          padding-bottom: 60vh;
        }
        @media screen and (max-width: 1366px) and (max-height: 1024px) {
          padding-bottom: 54vh;
        }
        @media screen and (max-width: 1250px) {
          padding-bottom: 57vh;
        }
        @media screen and (max-width: 1180px) and (max-height: 820px) {
          padding-bottom: 56vh;
        }
        @media screen and (max-width: 1024px) {
          padding-bottom: 55vh;
        }
        @media screen and (max-width: 850px) {
          padding-bottom: 56vh;
        }
        @media screen and (max-width: 768px) {
          padding-bottom: 62.5vh;
        }
        @media screen and (max-width: 430px) {
          padding-bottom: 43vh;
        }
        @media screen and (max-width: 375px) {
          padding-bottom: 23.5vh;
        }
      }
    }
  }
  .quality--images {
    z-index: 1;
    position: absolute;
    top: 55vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    // height: auto;
    .first_image,
    .second_image {
      .three_wrapper {
        display: none;
      }
    }
    .third_image {
      display: flex;
      align-items: center;
      justify-content: center;
      .three_wrapper {
        height: 200px;
        position: absolute;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 400px;
        top: 200px;
        p {
          position: relative;
          height: 200px;
          font-size: 16px;
          color: #000;
          span {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.5);
          }
          &:first-child {
            opacity: 0;
            max-width: 185px;
            padding-right: 5px;
            text-align: end;
            border-right: 1px solid black;
            left: -94px;
            height: 120px;
            top: 7px;
            display: flex;
            flex-direction: column;
            &:after {
              content: "";
              position: absolute;
              width: 3px;
              height: 3px;
              right: -2px;
              bottom: 0;
              background: black;
              border-radius: 50%;
            }
            @media screen and (max-width: 1600px) and (max-height: 900px) {
              left: -60px;
            }
            @media screen and (max-width: 1500px) and (max-height: 840px) {
              left: -83px;
              top: 43px;
            }
            @media screen and (max-width: 1440px) {
              top: 73px;
            }
            @media screen and (max-width: 1350px) {
              top: -40px;
            }
            @media screen and (max-width: 600px) {
              max-width: 150px;
              left: -70px;
            }
            @media screen and (max-width: 500px) {
              left: -58px;
              top: -25px;
            }
            @media screen and (max-width: 430px) {
              max-width: 130px;
              height: 95px;
              top: 3px;
              left: -50px;
            }
            @media screen and (max-width: 390px) {
              top: 16px;
            }
          }
          &:nth-child(2) {
            opacity: 0;
            max-width: 187px;
            padding-left: 5px;
            border-left: 1px solid black;
            left: 76px;
            height: 160px;
            top: 3px;
            display: flex;
            flex-direction: column;
            &:after {
              content: "";
              position: absolute;
              width: 3px;
              height: 3px;
              left: -2px;
              bottom: 0;
              background: black;
              border-radius: 50%;
            }
            @media screen and (max-width: 1600px) and (max-height: 900px) {
              top: 26px;
            }
            @media screen and (max-width: 1440px) {
              top: 73px;
            }
            @media screen and (max-width: 1350px) {
              top: -40px;
            }
            @media screen and (max-width: 600px) {
              max-width: 143px;
            }
            @media screen and (max-width: 500px) {
              left: 40px;
              top: -25px;
            }
            @media screen and (max-width: 430px) {
              max-width: 130px;
              height: 130px;
              top: 0;
            }
          }
          &:nth-child(3) {
            opacity: 0;
            max-width: 230px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-end;
            text-align: end;
            border-right: 1px solid black;
            border-right: 1px solid black;
            left: -218px;
            height: 137px;
            bottom: -86px;
            padding-right: 5px;
            &:after {
              content: "";
              position: absolute;
              width: 3px;
              height: 3px;
              right: -2px;
              top: 0;
              background: black;
              border-radius: 50%;
            }
            @media screen and (max-width: 1600px) and (max-height: 900px) {
              bottom: -114px;
            }
            @media screen and (max-width: 1440px) {
              bottom: -165px;
            }
            @media screen and (max-width: 1350px) {
              bottom: -65px;
            }
            @media screen and (max-width: 600px) {
              bottom: -50px;
              left: -85px;
              max-width: 185px;
            }
            @media screen and (max-width: 430px) {
              max-width: 143px;
              height: 123px;
              bottom: -64px;
              left: -71px;
            }
            @media screen and (max-width: 385px) {
              left: -62px;
            }
          }
          &:last-child {
            max-width: 46%;
            opacity: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
            padding-left: 5px;
            border-left: 1px solid black;
            right: -177px;
            height: 137px;
            bottom: 45px;
            padding-right: 5px;
            &:after {
              content: "";
              position: absolute;
              width: 3px;
              height: 3px;
              left: -2px;
              top: 0;
              background: black;
              border-radius: 50%;
            }
            @media screen and (max-width: 1600px) and (max-height: 900px) {
              bottom: 14px;
            }
            @media screen and (max-width: 1500px) and (max-height: 840px) {
              bottom: 2px;
            }
            @media screen and (max-width: 1440px) {
              bottom: -30px;
            }
            @media screen and (max-width: 1400px) and (max-height: 787px) {
              bottom: -8px;
            }
            @media screen and (max-width: 1350px) {
              bottom: 80px;
            }
            @media screen and (max-width: 600px) {
              bottom: -50px;
              left: 70px;
              max-width: 160px;
            }
            @media screen and (max-width: 500px) {
              bottom: -44px;
              left: 31px;
              max-width: 160px;
            }
            @media screen and (max-width: 430px) {
              height: 125px;
              bottom: -65px;
              left: 31px;
              max-width: 118px;
            }
            @media screen and (max-width: 390px) {
              bottom: -57px;
            }
          }

          @media screen and (max-width: 430px) {
            font-size: 12px;
            max-width: 150px;
            span {
              font-size: 12px;
            }
          }
          @media screen and (max-width: 600px) {
            font-size: 14px;
          }
          @media screen and (max-width: 430px) {
            font-size: 10px;
          }
        }
        @media screen and (max-width: 1600px) {
          top: 140px;
        }
        @media screen and (max-width:1194px) and (max-height: 820px) {
          top: 250px
        }
        @media screen and (max-width: 1180px) and (max-height: 820px) {
          top:250px
        }
        @media screen and (max-width: 1024px) {
          top: 250px;
        }
      }
    }

    &_item {
      position: absolute;
      height: 60vh;
      width: 60vw;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      @media screen and (max-width: 430px) {
        height: 250px;
      }
    }
    .first_image {
      z-index: 2;
      transform: scale(1.4);
      overflow: hidden;
      @media screen and (max-width: 1600px) {
        transform: scale(1.2);
      }
      @media screen and (max-width: 1350px) {
        transform: scale(1);
      }
      img {
        @media screen and (max-width: 900px) {
          transform: scale(2);
        }
      }
    }
    .second_image {
      z-index: 1;
      // transform: scale(0.98);
      transform: scale(1.385) translate(0, -2px);
      @media screen and (max-width: 1600px) {
        transform: scale(1.18) translate(0, -2px);
      }
      @media screen and (max-width: 1350px) {
        transform: scale(0.97);
      }
      img {
        @media screen and (max-width: 900px) {
          transform: scale(1.98);
        }
      }
    }
    .third_image {
      z-index: 0;
      height: 800px;
      width: 800px;
      transform: translate(-9px, 12px) scale(1.99);
      transition: transform 0.4s ease-in-out;
      img {
        @media screen and (max-width: 900px) {
          transform: translate(-6px, 5px) scale(1.17);
        }
        @media screen and (max-width: 768px) {
          transform: translate(-4px, 5px) scale(1.17);
        }
        @media screen and (max-width: 430px) {
          transform: translate(-2px, 2px) scale(1.17);
        }
      }
      @media screen and (max-width: 1905px) {
        transform: translate(-9px, 11px) scale(1.98);
      }
      @media screen and (max-width: 1885px) {
        transform: translate(-9px, 12px) scale(1.95);
      }
      @media screen and (max-width: 1850px) {
        transform: translate(-9px, 12px) scale(1.92);
      }
      @media screen and (max-width: 1820px) {
        transform: translate(-9px, 12px) scale(1.88);
      }
      @media screen and (max-width: 1800px) {
        transform: translate(-9px, 12px) scale(1.86);
      }
      @media screen and (max-width: 1780px) {
        transform: translate(-9px, 12px) scale(1.84);
      }
      @media screen and (max-width: 1760px) {
        transform: translate(-9px, 12px) scale(1.82);
      }
      @media screen and (max-width: 1740px) {
        transform: translate(-9px, 12px) scale(1.8);
      }
      @media screen and (max-width: 1720px) {
        transform: translate(-9px, 12px) scale(1.78);
      }
      @media screen and (max-width: 1700px) {
        transform: translate(-9px, 12px) scale(1.76);
      }
      @media screen and (max-width: 1680px) {
        transform: translate(-9px, 12px) scale(1.74);
      }
      @media screen and (max-width: 1660px) {
        transform: translate(-9px, 12px) scale(1.7);
      }
      @media screen and (max-width: 1640px) {
        transform: translate(-9px, 12px) scale(1.68);
      }
      @media screen and (max-width: 1620px) {
        transform: translate(-9px, 12px) scale(1.66);
      }
      @media screen and (max-width: 1600px) {
        transform: translate(-9px,6px) scale(1.42);
      }
      @media screen and (max-width: 1580px) {
        transform: translate(-9px, 8px) scale(1.38);
      }
      @media screen and (max-width: 1560px) {
        transform: translate(-9px, 8px) scale(1.36);
      }
      @media screen and (max-width: 1540px) {
        transform: translate(-9px, 8px) scale(1.34);
      }
      @media screen and (max-width: 1520px) {
        transform: translate(-9px, 8px) scale(1.32);
      }
      @media screen and (max-width: 1500px) {
        transform: translate(-5px, 5px) scale(1.35);
      }
      @media screen and (max-width: 1480px) {
        transform: translate(-9px, 8px) scale(1.28);
      }
      @media screen and (max-width: 1460px) {
        transform: translate(-9px, 8px) scale(1.26);
      }
      @media screen and (max-width: 1440px) {
        transform: translate(-5px, 7px) scale(1.272);
      }
      @media screen and (max-width: 1420px) {
        transform: translate(-9px, 8px) scale(1.22);
      }
      @media screen and (max-width: 1400px) {
        transform: translate(-9px, 8px) scale(1.2);
      }
      @media screen and (max-width: 1350px) {
        transform: translate(-3px, 4px) scale(0.98);
      }
      @media screen and (max-width: 1320px) {
        transform: translate(-3px, 4px) scale(0.96);
      }
      @media screen and (max-width: 1300px) {
        transform: translate(-3px, 4px) scale(0.94);
      }
      @media screen and (max-width: 1250px) {
        transform: translate(-3px, 4px) scale(0.9);
      }
      @media screen and (max-width: 1200px) {
        transform: translate(-3px, 4px) scale(0.86);
      }
      @media screen and (max-width: 1200px) {
        transform: translate(-3px, 4px) scale(0.84);
      }
      @media screen and (max-width: 1100px) {
        transform: translate(-3px, 4px) scale(0.81);
      }
      @media screen and (max-width: 1050px) {
        transform: translate(-4px, 4px) scale(0.78);
      }
      @media screen and (max-width: 1024px) {
        transform: translate(-3px, 4px) scale(0.76);
      }
      @media screen and (max-width: 1000px) {
        transform: translate(-3px, 2px) scale(0.74);
      }
      @media screen and (max-width: 950px) {
        transform: translate(-3px, 2px) scale(0.7);
      }
      @media screen and (max-width: 900px) {
        width: 100%;
        transform: scale(1);
      }
    }
    transition: transform 0.4s ease-in-out;
    &_active {
      transform: translateY(-20vh);
      transition: transform 0.4s ease-in-out;
    }
    &.static {
      // margin-bottom: 40vh;
      position: absolute !important;
      top: unset !important;
      bottom:45vh;
      @media screen and (max-width: 430px) {
        margin-bottom: 0;
        bottom: 50vh;
        position: absolute !important;
        top: unset !important;
      }
      @media screen and (max-width: 390px) {
        bottom: 578px;
      }
      @media screen and (max-width: 375px) {
        bottom: 325px;
      }
    }
    @media screen and (max-width: 430px) {
      top: 50vh;
    }
  }

  // .quality--block1 {
  //   background: $bgBrigth;
  //   p {
  //     padding: 40px 0 0 260px;
  //     max-width: 1165px;
  //     color: rgba(0, 0, 0, 0.5);
  //     font-size: 40px;
  //     font-style: normal;
  //     font-weight: 500;
  //     line-height: 30px;
  //     animation: textInBlock1 3s forwards;
  //     @media screen and (max-width: 1570px) {
  //       max-width: 1100px;
  //     }
  //     @media screen and (max-width: 1380px) {
  //       font-size: 34px;
  //     }
  //     @media screen and (max-width: 1000px) {
  //       font-size: 30px;
  //     }
  //     @media screen and (max-width: 768px) {
  //       font-size: 24px;
  //     }
  //     @media screen and (max-width: 600px) {
  //       font-size: 20px;
  //       line-height: normal;
  //     }
  //     span {
  //       color: #000;
  //       font-size: 40px;
  //       font-style: normal;
  //       font-weight: 500;
  //       line-height: normal;
  //       @media screen and (max-width: 1380px) {
  //         font-size: 34px;
  //       }
  //       @media screen and (max-width: 1000px) {
  //         font-size: 30px;
  //       }
  //       @media screen and (max-width: 768px) {
  //         font-size: 24px;
  //       }
  //       @media screen and (max-width: 600px) {
  //         font-size: 20px;
  //         line-height: normal;
  //       }
  //     }
  //     @media screen and (max-width: 1850px) {
  //       padding: 140px 0 0 240px;
  //     }
  //     @media screen and (max-width: 1750px) {
  //       padding: 140px 0 0 230px;
  //     }
  //     @media screen and (max-width: 1660px) {
  //       padding: 140px 0 0 205px;
  //     }
  //     @media screen and (max-width: 1570px) {
  //       padding: 40px 0 0 185px;
  //     }
  //     @media screen and (max-width: 1510px) {
  //       padding: 40px 0 0 170px;
  //     }
  //     @media screen and (max-width: 1450px) {
  //       padding: 40px 0 0 155px;
  //     }
  //     @media screen and (max-width: 1380px) {
  //       padding: 40px 0 0 140px;
  //     }
  //     @media screen and (max-width: 1305px) {
  //       padding: 40px 0 0 130px;
  //     }
  //     @media screen and (max-width: 1270px) {
  //       padding: 40px 0 0 120px;
  //     }
  //     @media screen and (max-width: 1220px) {
  //       padding: 40px 0 0 110px;
  //     }
  //     @media screen and (max-width: 1185px) {
  //       padding: 40px 100px 0 100px;
  //     }
  //     @media screen and (max-width: 1155px) {
  //       padding: 40px 90px 0 90px;
  //     }
  //     @media screen and (max-width: 1070px) {
  //       padding: 40px 70px 0 70px;
  //     }
  //     @media screen and (max-width: 1045px) {
  //       padding: 40px 60px 0 60p;
  //     }
  //   }
  //   .quality--image {
  //     width: 100%;
  //     overflow: hidden;
  //     height: 100%;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     img {
  //       transform: scale(0.85);
  //       width: 100%;
  //       height: 100%;
  //       object-fit: cover;
  //       position: relative;
  //       @media screen and (max-width: 1700px) {
  //         top: -80px;
  //       }
  //       @media screen and (max-width: 1400px) {
  //         top: -40px;
  //       }
  //       @media screen and (max-width: 500px) {
  //         top: 0;
  //       }
  //     }
  //   }
  // }
  // .quality--block1.active {
  //   p {
  //     animation: textOutBlock1 3s forwards;
  //   }
  // }

  // .quality--block2 {
  //   width: 100%;
  //   // position: absolute;
  //   // height: 0;
  //   // overflow: hidden;
  //   // bottom: 0;
  //   // z-index: 1;
  //   background: $bgDark;
  //   p {
  //     opacity: 0;
  //     padding: 90px 0 0 260px;
  //     max-width: 1165px;
  //     color: $discrColorDark;
  //     font-size: 40px;
  //     font-style: normal;
  //     font-weight: 500;
  //     animation: textOutBlock2 3s forwards;
  //     @media screen and (max-width: 1570px) {
  //       max-width: 1100px;
  //     }
  //     @media screen and (max-width: 1380px) {
  //       font-size: 34px;
  //     }
  //     @media screen and (max-width: 1000px) {
  //       font-size: 30px;
  //     }
  //     @media screen and (max-width: 768px) {
  //       font-size: 24px;
  //     }
  //     @media screen and (max-width: 600px) {
  //       font-size: 20px;
  //       line-height: normal;
  //     }
  //     span {
  //       color: $titleColor;
  //       font-size: 40px;
  //       font-style: normal;
  //       font-weight: 500;
  //       @media screen and (max-width: 1380px) {
  //         font-size: 34px;
  //       }
  //       @media screen and (max-width: 1000px) {
  //         font-size: 30px;
  //       }
  //       @media screen and (max-width: 768px) {
  //         font-size: 24px;
  //       }
  //       @media screen and (max-width: 600px) {
  //         font-size: 20px;
  //         line-height: normal;
  //       }
  //     }
  //     @media screen and (max-width: 1850px) {
  //       padding: 140px 0 0 240px;
  //     }
  //     @media screen and (max-width: 1750px) {
  //       padding: 140px 0 0 230px;
  //     }
  //     @media screen and (max-width: 1660px) {
  //       padding: 140px 0 0 205px;
  //     }
  //     @media screen and (max-width: 1570px) {
  //       padding: 40px 0 0 185px;
  //     }
  //     @media screen and (max-width: 1510px) {
  //       padding: 40px 0 0 170px;
  //     }
  //     @media screen and (max-width: 1450px) {
  //       padding: 40px 0 0 155px;
  //     }
  //     @media screen and (max-width: 1380px) {
  //       padding: 40px 0 0 140px;
  //     }
  //     @media screen and (max-width: 1305px) {
  //       padding: 40px 0 0 130px;
  //     }
  //     @media screen and (max-width: 1270px) {
  //       padding: 40px 0 0 120px;
  //     }
  //     @media screen and (max-width: 1220px) {
  //       padding: 40px 0 0 110px;
  //     }
  //     @media screen and (max-width: 1185px) {
  //       padding: 40px 100px 0 100px;
  //     }
  //     @media screen and (max-width: 1155px) {
  //       padding: 40px 90px 0 90px;
  //     }
  //     @media screen and (max-width: 1070px) {
  //       padding: 40px 70px 0 70px;
  //     }
  //     @media screen and (max-width: 1045px) {
  //       padding: 40px 60px 0 60p;
  //     }
  //   }
  //   .quality--image {
  //     width: 100%;
  //     overflow: hidden;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     img {
  //       // position: absolute;
  //       // bottom: 6px;
  //       // transform: scale(0.845);
  //       width: 100%;
  //       object-fit: cover;
  //       @media screen and (max-width: 1700px) {
  //         bottom: 86px;
  //       }
  //       @media screen and (max-width: 1400px) {
  //         bottom: 46px;
  //       }
  //       @media screen and (max-width: 500px) {
  //         bottom: 6px;
  //       }
  //     }
  //   }
  // }
  // .quality--block2.active {
  //   p {
  //     animation: textOnBlock2 3s forwards;
  //   }
  // }
  // .quality--block3 {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   width: 100%;
  //   // position: absolute;
  //   // height: 0;
  //   // bottom: 0;
  //   z-index: 1;
  //   overflow: hidden;
  //   background: gray;
  //   .quality--image {
  //     width: 100%;
  //     overflow: hidden;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     img {
  //       // position: absolute;
  //       // bottom: -350px;
  //       // left: -10px;
  //       width: 100%;
  //       object-fit: cover;
  //       animation: normalImage 3s forwards;
  //       max-height: 1500px;
  //       @media screen and (max-width: 1800px) {
  //         left: -10px;
  //         bottom: -405px;
  //       }
  //       @media screen and (max-width: 1700px) {
  //         bottom: -295px;
  //       }
  //       @media screen and (max-width: 1650px) {
  //         bottom: -285px;
  //       }
  //       @media screen and (max-width: 1600px) {
  //         bottom: -275px;
  //       }
  //       @media screen and (max-width: 1550px) {
  //         bottom: -265px;
  //       }
  //       @media screen and (max-width: 1500px) {
  //         bottom: -252px;
  //       }
  //       @media screen and (max-width: 1450px) {
  //         bottom: -240px;
  //       }
  //       @media screen and (max-width: 1400px) {
  //         bottom: -270px;
  //       }
  //       @media screen and (max-width: 1350px) {
  //         bottom: -260px;
  //       }
  //       @media screen and (max-width: 1300px) {
  //         bottom: -250px;
  //       }
  //       @media screen and (max-width: 1250px) {
  //         bottom: -240px;
  //       }
  //       @media screen and (max-width: 1200px) {
  //         bottom: -230px;
  //       }
  //       @media screen and (max-width: 1150px) {
  //         bottom: -220px;
  //       }
  //       @media screen and (max-width: 1200px) {
  //         bottom: -210px;
  //       }
  //       @media screen and (max-width: 1050px) {
  //         bottom: -200px;
  //       }
  //       @media screen and (max-width: 1024px) {
  //         bottom: -185px;
  //         left: -8px;
  //       }
  //       @media screen and (max-width: 1000px) {
  //         bottom: -180px;
  //       }
  //       @media screen and (max-width: 950px) {
  //         bottom: -170px;
  //         left: -5px;
  //       }
  //       @media screen and (max-width: 900px) {
  //         bottom: -158px;
  //       }
  //       @media screen and (max-width: 850px) {
  //         bottom: -147px;
  //       }
  //       @media screen and (max-width: 800px) {
  //         bottom: -135px;
  //       }
  //       @media screen and (max-width: 750px) {
  //         bottom: -122px;
  //       }
  //       @media screen and (max-width: 700px) {
  //         bottom: -112px;
  //       }
  //       @media screen and (max-width: 650px) {
  //         bottom: -98px;
  //       }
  //       @media screen and (max-width: 600px) {
  //         bottom: -88px;
  //       }
  //       @media screen and (max-width: 550px) {
  //         bottom: -75px;
  //       }
  //       @media screen and (max-width: 500px) {
  //         bottom: -105px;
  //         left: -3px;
  //       }
  //       @media screen and (max-width: 450px) {
  //         bottom: -100px;
  //         left: -2px;
  //       }
  //       @media screen and (max-width: 400px) {
  //         bottom: -95px;
  //       }
  //       @media screen and (max-width: 390px) {
  //         bottom: -92px;
  //       }
  //       @media screen and (max-width: 380px) {
  //         bottom: -90px;
  //       }
  //       @media screen and (max-width: 370px) {
  //         bottom: -88px;
  //       }
  //       @media screen and (max-width: 360px) {
  //         bottom: -86px;
  //       }
  //       @media screen and (max-width: 350px) {
  //         bottom: -84px;
  //       }
  //     }
  //   }
  //   .animated--text1 {
  //     opacity: 1;
  //     position: absolute;
  //     p {
  //       opacity: 0;
  //       position: relative;
  //       font-size: 16px;
  //       color: #000;
  //       border-right: 1px solid black;
  //       span {
  //         font-size: 16px;
  //         color: rgba(0, 0, 0, 0.5);
  //         @media screen and (max-width: 1380px) {
  //           font-size: 14px;
  //         }
  //         @media screen and (max-width: 768px) {
  //           font-size: 12px;
  //         }
  //         @media screen and (max-width: 500px) {
  //           font-size: 9px;
  //         }
  //       }
  //       &:first-child {
  //         padding: 0 10px 0;
  //         left: -200px;
  //         top: -40px;
  //         min-height: 150px;
  //         max-width: 240px;
  //         text-align: end;
  //         &:after {
  //           content: "";
  //           position: absolute;
  //           width: 5px;
  //           height: 5px;
  //           right: -3px;
  //           bottom: 0;
  //           background: black;
  //           border-radius: 50%;
  //         }
  //         @media screen and (max-width: 1850px) {
  //           top: 0;
  //         }
  //         @media screen and (max-width: 1800px) {
  //           top: 45px;
  //         }
  //         @media screen and (max-width: 1700px) {
  //           top: -40px;
  //         }
  //         @media screen and (max-width: 1650px) {
  //           top: -25px;
  //         }
  //         @media screen and (max-width: 1600px) {
  //           top: -20px;
  //         }
  //         @media screen and (max-width: 1550px) {
  //           top: 15px;
  //         }
  //         @media screen and (max-width: 1500px) {
  //           top: -15px;
  //           left: -220px;
  //         }
  //         @media screen and (max-width: 1450px) {
  //           top: -100px;
  //         }
  //         @media screen and (max-width: 1400px) {
  //           top: -40px;
  //         }
  //         @media screen and (max-width: 1350px) {
  //           top: -15px;
  //         }
  //         @media screen and (max-width: 1300px) {
  //           top: 25px;
  //           min-height: 100px;
  //         }
  //         @media screen and (max-width: 1250px) {
  //           min-height: 120px;
  //         }
  //         @media screen and (max-width: 1150px) {
  //           top: 36px;
  //         }
  //         @media screen and (max-width: 1100px) {
  //           left: -200px;
  //         }
  //         @media screen and (max-width: 1050px) {
  //           top: 52px;
  //         }
  //         @media screen and (max-width: 950px) {
  //           min-height: 100px;
  //           top: -65px;
  //           left: -182px;
  //         }
  //         @media screen and (max-width: 900px) {
  //           min-height: 100px;
  //           top: -60px;
  //         }
  //         @media screen and (max-width: 850px) {
  //           top:-35px
  //         }
  //         @media screen and (max-width:800px) {
  //           top:-24px
  //         }
  //         @media screen and (max-width: 768px) {
  //           top:-7px;
  //         }
  //         @media screen and (max-width: 700px) {
  //        left: -170px;
  //         }
  //         @media screen and (max-width:650px) {
  //           left: -157px;
  //         }
  //         @media screen and (max-width: 600px) {
  //           min-height: 80px;
  //           top: -60px;
  //         }
  //         @media screen and (max-width: 550px) {
  //          max-width: 130px;
  //          left: -96px;
  //          top: -45px;
  //         }
  //         @media screen and (max-width: 500px) {
  //           top: -5px;
  //           left: -92px;
  //         }
  //         @media screen and (max-width: 450px) {
  //           top:-3px;
  //           left: -79px;
  //         }
  //         @media screen and (max-width: 430px) {
  //           top:18px;
  //         }
  //         @media screen and (max-width: 410px) {
  //           top:27px;
  //         }
  //         @media screen and (max-width: 400px) {
  //           top:30px;
  //         }
  //         @media screen and (max-width: 390px) {
  //           top:33x;
  //         }
  //         @media screen and (max-width: 375px) {
  //           top:36px;
  //         }
  //         @media screen and (max-width: 350px) {
  //           top: 46px;
  //         }
  //       }
  //       &:last-child {
  //         border-left: 1px solid black;
  //         border-right: none;
  //         display: flex;
  //         align-items: flex-start;
  //         justify-content: flex-end;
  //         flex-direction: column;
  //         padding: 0 10px 0;
  //         left: 250px;
  //         top: 130px;
  //         min-height: 200px;
  //         max-width: 240px;
  //         &:after {
  //           content: "";
  //           position: absolute;
  //           width: 5px;
  //           height: 5px;
  //           left: -3px;
  //           top: 0;
  //           background: black;
  //           border-radius: 50%;
  //         }
  //         @media screen and (max-width: 1850px) {
  //           top: 150px;
  //         }
  //         @media screen and (max-width: 1800px) {
  //           top: 210px;
  //           left: 240px;
  //         }
  //         @media screen and (max-width: 1700px) {
  //           top: 115px;
  //           left: 200px;
  //         }
  //         @media screen and (max-width: 1650px) {
  //           top: 122px;
  //         }
  //         @media screen and (max-width: 1600px) {
  //           top: 127px;
  //         }
  //         @media screen and (max-width: 1550px) {
  //           top: 140px;
  //         }
  //         @media screen and (max-width: 1500px) {
  //           top: 120px;
  //           min-height: 150px;
  //         }
  //         @media screen and (max-width: 1450px) {
  //           top: -5px;
  //         }
  //         @media screen and (max-width: 1400px) {
  //           top: 70px;
  //           left: 165px;
  //         }
  //         @media screen and (max-width: 1350px) {
  //           top: 79px;
  //         }
  //         @media screen and (max-width: 1300px) {
  //           top: 115px;
  //         }
  //         @media screen and (max-width: 1200px) {
  //           top: 105px;
  //         }
  //         @media screen and (max-width: 1150px) {
  //           min-height: 100px;
  //           top: 95px;
  //         }
  //         @media screen and (max-width: 1100px) {
  //           top: 115px;
  //         }
  //         @media screen and (max-width: 1050px) {
  //           top: 125px;
  //         }
  //         @media screen and (max-width: 950px) {
  //           top: 5px;
  //         }
  //         @media screen and (max-width: 900px) {
  //           min-height: 100px;
  //           top: 15px;
  //           left: 155px;
  //         }
  //         @media screen and (max-width: 850px) {
  //           top: 25px;
  //         }
  //         @media screen and (max-width:768px) {
  //           top:35px;
  //         }
  //         @media screen and (max-width: 700px) {
  //           top: 40px;
  //         }
  //         @media screen and (max-width:650px) {
  //           top: 45px;
  //         }
  //         @media screen and (max-width: 600px) {
  //           min-height: 80px;
  //           top: -14px;
  //         }
  //         @media screen and (max-width: 550px) {
  //           left: 87px;
  //           top: -4px;
  //           max-width: 145px;
  //         }
  //         @media screen and (max-width: 500px) {
  //           top: 31px;
  //           left:87px;
  //         }
  //         @media screen and (max-width: 450px) {
  //           top: 36px;
  //           left: 85px;
  //         }
  //         @media screen and (max-width: 430px) {
  //           top: 55px;
  //         }
  //         @media screen and (max-width: 414px) {
  //           top: 65px;
  //         }
  //         @media screen and (max-width: 350px) {
  //           top: 75px;
  //         }
  //       }
  //       @media screen and (max-width: 1380px) {
  //         font-size: 14px;
  //       }
  //       @media screen and (max-width: 768px) {
  //         font-size: 12px;
  //       }
  //       @media screen and (max-width: 500px) {
  //         font-size: 9px;
  //       }
  //     }
  //   }
  //   .animated--text2 {
  //     position: absolute;
  //     opacity: 1;
  //     p {
  //       opacity: 0;
  //       position: relative;
  //       font-size: 16px;
  //       color: #000;
  //       border-left: 1px solid black;
  //       span {
  //         font-size: 16px;
  //         color: rgba(0, 0, 0, 0.5);
  //         @media screen and (max-width: 1380px) {
  //           font-size: 14px;
  //         }
  //         @media screen and (max-width: 768px) {
  //           font-size: 12px;
  //         }
  //         @media screen and (max-width: 500px) {
  //           font-size: 9px;
  //         }
  //       }
  //       &:first-child {
  //         padding: 0 10px 0;
  //         left: 250px;
  //         top: -30px;
  //         min-height: 240px;
  //         max-width: 330px;
  //         &:after {
  //           content: "";
  //           position: absolute;
  //           width: 5px;
  //           height: 5px;
  //           left: -3px;
  //           bottom: 0;
  //           background: black;
  //           border-radius: 50%;
  //         }
  //         @media screen and (max-width: 1800px) {
  //           top: 50px;
  //         }
  //         @media screen and (max-width: 1750px) {
  //           top: 50px;
  //         }
  //         @media screen and (max-width: 1700px) {
  //           top: -70px;
  //           left: 220px;
  //         }
  //         @media screen and (max-width: 1650px) {
  //           top: -45px;
  //         }
  //         @media screen and (max-width: 1550px) {
  //           top: 0;
  //         }
  //         @media screen and (max-width: 1500px) {
  //           min-height: 170px;
  //           top: 15px;
  //         }
  //         @media screen and (max-width: 1450px) {
  //           top: -100px;
  //         }
  //         @media screen and (max-width: 1400px) {
  //           top: -25px;
  //         }
  //         @media screen and (max-width: 1350px) {
  //           top: -5px;
  //         }
  //         @media screen and (max-width: 1300px) {
  //           min-height: 120px;
  //           top: 15px;
  //           left: 205px;
  //         }
  //         @media screen and (max-width: 1250px) {
  //           top: 25px;
  //         }
  //         @media screen and (max-width: 1100px) {
  //           top: 45px;
  //         }
  //         @media screen and (max-width: 1050px) {
  //           top: 65px;
  //         }
  //         @media screen and (max-width: 950px) {
  //           top: -40px;
  //         }
  //         @media screen and (max-width: 900px) {
  //           max-width: 270px;
  //           left: 164px;
  //           top: -50px;
  //         }
  //         @media screen and (max-width: 800px) {
  //           left: 170px;
  //           top: -25px;
  //         }
  //         @media screen and (max-width: 768px) {
  //           left: 160px;
  //           top: -15px;
  //         }
  //         @media screen and (max-width:650px) {
  //           top: 0;
  //         }
  //         @media screen and (max-width: 600px) {
  //           min-height: 100px;
  //           top: -60px;
  //         }
  //         @media screen and (max-width: 550px) {
  //           top: -50px;
  //           left: 111px;
  //           max-width: 190px;
  //         }
  //         @media screen and (max-width: 500px) {
  //           top: 0;
  //           max-width: 140px;
  //           min-height: 70px;
  //           left: 90px;
  //         }
  //         @media screen and (max-width: 450px) {
  //           top: 5px;
  //         }
  //         @media screen and (max-width:430px) {
  //           top: 28px;
  //         }
  //         @media screen and (max-width: 414px) {
  //           top: 40px;
  //         }
  //         @media screen and (max-width: 350px) {
  //           top: 48px;
  //           left: 84px;
  //         }
  //       }
  //       &:last-child {
  //         display: flex;
  //         align-items: flex-end;
  //         justify-content: flex-end;
  //         flex-direction: column;
  //         padding: 0 10px 0;
  //         left: -150px;
  //         top: 100px;
  //         min-height: 140px;
  //         max-width: 250px;
  //         border-left: none;
  //         border-right: 1px solid black;
  //         text-align: end;
  //         &:after {
  //           content: "";
  //           position: absolute;
  //           width: 5px;
  //           height: 5px;
  //           right: -3px;
  //           top: 0;
  //           background: black;
  //           border-radius: 50%;
  //         }
  //         @media screen and (max-width: 1800px) {
  //           top: 170px;
  //         }
  //         @media screen and (max-width: 1750px) {
  //           top: 170px;
  //         }
  //         @media screen and (max-width: 1700px) {
  //           top: 60px;
  //         }
  //         @media screen and (max-width: 1600px) {
  //           top: 80px;
  //         }
  //         @media screen and (max-width: 1500px) {
  //           top: 125px;
  //         }
  //         @media screen and (max-width: 1450px) {
  //           top: -10px;
  //         }
  //         @media screen and (max-width: 1400px) {
  //           top: 65px;
  //         }
  //         @media screen and (max-width: 1300px) {
  //           top: 106px;
  //         }
  //         @media screen and (max-width: 1150px) {
  //           min-height: 100px;
  //         }
  //         @media screen and (max-width: 1100px) {
  //           top: 125px;
  //         }
  //         @media screen and (max-width: 950px) {
  //           top: 0;
  //         }
  //         @media screen and (max-width: 900px) {
  //           left: -160px;
  //           top: 20px;
  //         }
  //         @media screen and (max-width: 768px) {
  //           top: 30px;
  //         }
  //         @media screen and (max-width:600px) {
  //           min-height: 80px;
  //           top: -20px;
  //         }
  //         @media screen and (max-width: 550px) {
  //           top: -11px;
  //           left: -98px;
  //           max-width: 160px;
  //         }
  //         @media screen and (max-width: 500px) {
  //           top: 36px;
  //           min-height: 70px;
  //           max-width: 140px;
  //         }
  //         @media screen and (max-width:430px) {
  //           top: 50px;
  //         }
  //         @media screen and (max-width: 375px) {
  //           top: 55px;
  //         }
  //         @media screen and (max-width: 350px) {
  //           top: 75px;
  //           left: -93px;
  //         }
  //       }
  //       @media screen and (max-width: 1380px) {
  //         font-size: 14px;
  //       }
  //       @media screen and (max-width: 768px) {
  //         font-size: 12px;
  //       }
  //       @media screen and (max-width: 500px) {
  //         font-size: 9px;
  //       }
  //     }
  //   }
  //   &.active {
  //     .quality--image {
  //       img {
  //         animation: smallImage 1s forwards;
  //       }
  //     }
  //     .animated--text1 {
  //       p {
  //         animation: opacityText 1s forwards;
  //       }
  //     }
  //   }
  //   &.activeText {
  //     .animated--text2 {
  //       p {
  //         animation: opacityText 1s forwards;
  //       }
  //     }
  //   }
  //   &.disactive {
  //     .animated--text1 {
  //       animation: opacityReverse 1s forwards;
  //     }
  //     .quality--image {
  //       img {
  //         animation: normalImage 2s forwards;
  //       }
  //     }
  //   }
  // }
}

// @keyframes textOutBlock1 {
//   0% {
//     transform: translateY(0);
//     opacity: 1;
//   }
//   100% {
//     opacity: 0;
//     transform: translateY(-300px);
//   }
// }
// @keyframes textInBlock1 {
//   0% {
//     opacity: 0;
//     transform: translateY(-300px);
//   }
//   100% {
//     transform: translateY(0);
//     opacity: 1;
//   }
// }
// @keyframes textOutBlock2 {
//   0% {
//     transform: translateY(0);
//     opacity: 1;
//   }
//   100% {
//     opacity: 0;
//     transform: translateY(-300px);
//   }
// }
// @keyframes textOnBlock2 {
//   0% {
//     opacity: 0;
//     transform: translateY(300px);
//   }
//   100% {
//     transform: translateY(0);
//     opacity: 1;
//   }
// }

// @keyframes smallImage {
//   0% {
//     transform: scale(0.86) translateY(0);
//   }
//   100% {
//     transform: scale(0.5) translateY(-180px);
//   }
// }
// @keyframes normalImage {
//   0% {
//     transform: scale(0.5) translateY(-180px);
//   }
//   100% {
//     transform: scale(0.86) translateY(0);
//   }
// }
// @keyframes opacityText {
//   0% {
//     opacity: 0;
//   }
//   90% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }
// @keyframes opacityReverse {
//   0% {
//     opacity: 1;
//   }
//   30% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 0;
//   }
// }
