.environment-container_chasracteristic {
  margin: 0 30px 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  .char-description {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    min-height: 550px;
    min-width: 450px;
    div {
      max-width: 275px;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      h3 {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 20px;
        max-width: 100px;
        min-height: 45px;
        @media screen and (max-width: 1350px) {
          font-size: 18px;
          margin-bottom: 30px;
        }
        @media screen and (max-width: 1250px) {
          font-size: 16px;
        }
        @media screen and (max-width: 390px) {
          font-size: 12px;
        }
      }
      &:first-child {
        margin: 50px 0 90px;
        h3 {
          font-size: 44px;
          font-style: normal;
          font-weight: 500;
          margin-bottom: 0;
          @media screen and (max-width: 1350px) {
            font-size: 35px;
          }
          @media screen and (max-width: 1250px) {
            font-size: 30px;
          }
          @media screen and (max-width: 768px) {
            font-size: 24px;
          }
        }
        @media screen and (max-width: 1250px) {
          margin: 50px 0 110px;
        }
        @media screen and (max-width: 768px) {
          margin: 50px 0 130px;
        }
        @media screen and (max-width: 470px) {
          h3 {
            top: -90px;
            left: 30vw;
            position: relative;
            font-size: 20px;
            text-align: center;
            max-width: 150px;
          }
        }
      }
      &:nth-child(2) {
        h3 {
          margin-bottom: 0;
          @media screen and (max-width: 1350px) {
            margin-bottom: 10px;
          }
          @media screen and (max-width: 768px) {
            margin-bottom: 0;
          }
          @media screen and (max-width: 470px) {
            margin-bottom: 10px;
          }
        }
      }
      &:nth-child(4) {
        h3 {
          @media screen and (max-width: 470px) {
            margin-bottom: 20px;
          }
        }
      }
      &:nth-child(5) {
        h3 {
          @media screen and (max-width: 470px) {
            margin-bottom: 26px;
          }
        }
      }
      &:last-child {
        h3 {
          margin-bottom: 0;
          margin-top: 10px;
          @media screen and (max-width: 1350px) {
            margin-top: 0;
          }
        }
      }
    }
    @media screen and (max-width: 1435px) {
      min-width: 400px;
    }
    @media screen and (max-width: 1195px) {
      min-width: 350px;
    }
    @media screen and (max-width: 640px) {
      min-width: 180px;
    }
    @media screen and (max-width: 470px) {
      min-width: 50px;
    }
  }
  .char-types {
    display: flex;
    align-items: flex-end;
    .char-types_name {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      min-width: 250px;
      .char-types_image {
        height: 178px;
        width: 150px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .char__text {
        min-width: 335px;
      }
      h4 {
        color: $defColor;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        max-width: 100px;
        margin: 10px 0 20px;
      }
      .type_item {
        display: flex;
        justify-content: flex-start;
        min-width: 265px;
        margin-bottom: 15px;
        .char-types_name-item_container {
          min-width: 70px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .circle {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background: #cfced8;
          }
          .circle.active {
            background: $titleColor;
          }
          p {
            margin-top: 7px;
            color: $defColor;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            width: 82px;
            word-wrap: break-word;
            text-align: center;
          }
        }
      }
      &:first-child {
        .char-types_image {
          position: relative;
          left: -35px;
          img {
            transform: scale(1.1);
          }
        }
        h4 {
          position: relative;
          left: -45px;
        }
      }
      &:nth-child(2) {
        .char-types_image {
          position: relative;
          left: -35px;
        }
        h4 {
          position: relative;
          left: -45px;
        }
      }
      &:last-child {
        .char-types_image {
          img {
            transform: scale(1.1);
          }
        }
      }
    }
    @media screen and (max-width: 1130px) {
      max-width: 250px;
      overflow-x: scroll;
    }
  }
  .slides-items {
    display: none;
    height: 30px;
    width: 80px;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: -30px;
    .slide-item {
      width: 15px;
      height: 15px;
      background: $bgGrey;
      &:first-child {
        border-radius: 5px;
      }
      &:nth-child(2) {
        border-radius: 10px;
      }
      &.active {
        background: $bgDark;
      }
    }
    @media screen and (max-width: 1130px) {
      display: flex;
    }
  }
  @media screen and (max-width: 470px) {
    margin: 0 10px 40px;
  }
}
