.playground {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: $bgBrigth;
  height: 100vh;
  &--title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    h1 {
      color: $titleColor;
      font-size: 104px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      @media screen and (max-width: 1350px) {
        font-size: 90px;
      }
      @media screen and (max-width: 1250px) {
        font-size: 80px;
      }
      @media screen and (max-width: 1000px) {
        font-size: 70px;
      }
      @media screen and (max-width: 768px) {
        font-size: 55px;
      }
      @media screen and (max-width: 500px) {
        font-size: 40px;
      }
      @media screen and (max-width: 375px) {
        font-size: 30px;
      }
    }
    p {
      margin-top: 30px;
      max-width: 900px;
      font-size: 31px;
      font-style: normal;
      font-weight: 400;
      @media screen and (max-width: 1350px) {
        font-size: 28px;
      }
      @media screen and (max-width: 1250px) {
        font-size: 24px;
      }
      @media screen and (max-width: 1000px) {
        font-size: 22px;
      }
      @media screen and (max-width: 768px) {
        font-size: 20px;
      }
      @media screen and (max-width: 500px) {
        font-size: 16px;
        margin-top: 15px;
      }
      @media screen and (max-width: 375px) {
        font-size: 14px;
      }
    }
  }
  &--links {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    .catalog {
      cursor: pointer;
      margin-right: 25px;
      min-height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
       a {
        color: rgba(0, 0, 0, 0.51);
        transition: color 0.3s ease-in-out;
        }
        svg {
          path {
            fill: rgba(0, 0, 0, 0.51);
            transition: fill 0.3s ease-in-out;
          }
       }
      }
      a {
        color: $defColor;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        transition: color 0.3s ease-in-out;
        @media screen and (max-width: 1350px) {
          font-size: 24px;
        }
        @media screen and (max-width: 1000px) {
          font-size: 22px;
        }
        @media screen and (max-width: 768px) {
          font-size: 20px;
        }
        @media screen and (max-width: 500px) {
          font-size: 16px;
        }
        @media screen and (max-width: 375px) {
          font-size: 14px;
        }
      }
      svg {
        margin: 5px 0 0 5px;
        path {
          transition: fill 0.3s ease-in-out;
        }
        @media screen and (max-width: 450px) {
          margin: 0 0 0 5px;
          width: 12px;
          height: 12px;
        }
      }
    }
    .file {
      cursor: pointer;
      user-select: none;
      margin-left: 25px;
      min-height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        p {
         color: rgba(0, 0, 0, 0.51);
         transition: color 0.3s ease-in-out;
         }
         svg {
           path {
             fill: rgba(0, 0, 0, 0.51);
             transition: fill 0.3s ease-in-out;
           }
        }
       }
      p {
        margin-top: 0;
        color: $defColor;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        transition: color 0.3s ease-in-out;
        &:hover {
          color: rgba(0, 0, 0, 0.51);
          transition: color 0.3s ease-in-out;
        }
        @media screen and (max-width: 1350px) {
          font-size: 24px;
        }
        @media screen and (max-width: 1000px) {
          font-size: 22px;
        }
        @media screen and (max-width: 768px) {
          font-size: 20px;
        }
        @media screen and (max-width: 500px) {
          font-size: 16px;
        }
        @media screen and (max-width: 375px) {
          font-size: 14px;
        }
      }
      svg {
        margin: 5px 0 0 5px;
        @media screen and (max-width: 450px) {
          margin: 0 0 0 5px;
          width: 13px;
          height: 13px;
        }
      }
    }
    @media screen and (max-width: 500px) {
      margin-top: 20px;
    }
  }
  @media screen and (max-width: 1000px) {
    font-size: 30px;
    padding: 0 35px;
  }
  @media screen and (max-width: 1025px) {
    height: 100%;
    padding: 100px 35px;
  }
  @media screen and (max-width: 450px) {
    padding: 50px 35px 0;
  }
}
