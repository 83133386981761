.footer {
  background: $footerBg;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 60px 61px 56px;
  &--nav {
    margin-top:45px;
    min-height: 156px;
    .footer--logo {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      max-width: 300px;
      min-height: 77px;
      a {
        img {
          width: 100%;
          object-fit: contain;
        }
      }
      @media screen and (max-width: 1250px) {
        min-height: 75px;
      }
    }
    nav {
      margin-top: 60px;
      ul {
        display: flex;
        align-items: center;
        justify-content: center;
        li {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 136px;
          margin: 0 20px;
          border-radius: 100px;
          background: #e1e051;
          padding: 8px 20px;
          border: 2px solid #e1e051;
          transition: background 0.3s ease-in-out;
          &:hover {
            background: #f3f3b9;
            transition: background 0.3s ease-in-out;
          }
          a {
            color: #000;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            @media screen and (max-width: 1325px) {
              font-size: 14px;
            }
          }
          &:last-child {
            background: transparent;
            border: 2px solid #000;
            transition: border 0.3s ease-in-out;
            a {
              color: #000;
              transition: color 0.3s ease-in-out;
              &:hover {
                transition: color 0.3s ease-in-out;
                color: #818181;
              }
            }
            &:hover {
              transition: border 0.3s ease-in-out;
              border: 2px solid #818181;
            }
          }
          @media screen and (max-width: 1325px) {
            margin: 0 7px;
          }
        }
      }
    }
    @media screen and (max-width: 1188px) {
      display: none;
    }
  }
  &--form {
    margin-top: 45px;
    min-height: 156px;
    form {
      display: flex;
      flex-direction: column;
      p {
        padding-left: 10px;
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 39px; /* 144.444% */
      }
      .form--inputs {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        input:first-child {
          display: flex;
          width: 500px;
          padding: 7px 10px 7px 20px;
          align-items: center;
          gap: 10px;
          flex-shrink: 0;
          border-radius: 100px;
          background: transparent;
          border: 2px solid #000;
          @media screen and (max-width: 1325px) {
            width: 290px;
          }
        }
        input:last-child {
          cursor: pointer;
          margin-left: 20px;
          padding: 7px 10px;
          text-align: center;
          min-width: 137px;
          border-radius: 100px;
          background: #e1e051;
          border: 2px solid #e1e051;
          color: #000;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          transition: background 0.3s ease-in-out;
          &:hover {
            transition: background 0.3s ease-in-out;
            background: #F3F3B9;
          }
          &:disabled {
            transition: opacity 0.4s ease-in-out;
            opacity: 0.5;
            transition: background 0.3s ease-in-out;
          }
          @media screen and (max-width: 1325px) {
            font-size: 16px;
          }
        }
      }
      .checkbox-container {
        margin: 20px 0;
        display: flex;
        align-items: center;
        input {
          position: relative;
          width: 20px;
          height: 20px;
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          -ms-appearance: none; 
          border-radius: 50%;
          border: 1px solid black;
          cursor: pointer;
          &:checked{
            background: black;
          }
        }
        p {
          color: rgba(0, 0, 0, 0.6);
          margin: 0 0 0 10px;
          max-height: 30px;
          max-width: 500px;
          font-size: 12px;
          line-height: normal;
          a {
            color: rgba(0, 0, 0, 0.6);
            text-decoration: underline;
          }
        }
      }
    }
    @media screen and (max-width: 1188px) {
      display: none;
    }
  }
  &--links {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 140px;
      margin: 0 20px;
      border-radius: 100px;
      border: 2px solid #000;
      padding: 8px 20px;
      transition: border 0.3s ease-in-out;
      &:hover {
        transition: border 0.3s ease-in-out;
        border: 2px solid #818181;
      }
      a {
        color: #000;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        transition: color 0.3s ease-in-out;
        &:hover {
          transition: color 0.3s ease-in-out;
          color: #818181;
        }
        @media screen and (max-width: 1325px) {
          font-size: 14px;
        }
      }
      &:first-child {
        margin: 0 20px 0 0;
        @media screen and (max-width: 1325px) {
          margin: 0 10px 0 0;
        }
      }
      &:last-child {
        user-select: none;
        border: none;
        padding: 16px 0 0;
        margin: 0 10px 0 0;
      }
      @media screen and (max-width: 1325px) {
        margin: 0 10px;
      }
    }
    @media screen and (max-width: 1188px) {
      display: none;
    }
  }
  .tiny--footer {
    display: none;
    @media screen and (max-width: 1188px) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      &_logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 75px;
        height: 75px;
        a {
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
      &_links {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 450px;
        li {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 136px;
          border-radius: 100px;
          border: 2px solid #000;
          padding: 8px 20px;
          a {
            color: #000;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            @media screen and (max-width: 480px) {
              font-size: 14px;
              min-width: 81px;
            }
          }
          @media screen and (max-width: 480px) {
            min-width: 100px;
            &:nth-child(2) {
              margin: 0 10px;
            }
          }
          @media screen and (max-width: 380px) {
            padding: 8px 15px;
          }
        }
        @media screen and (max-width: 480px) {
          width: 350px;
        }
        @media screen and (max-width: 375px) {
          width: 330px;
        }
      }
      &_form {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 450px;

        p {
          margin: 45px 20px 20px;
          width: 100%;
          text-align: start;
          color: #000;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          @media screen and (max-width: 480px) {
            font-size: 20px;
          }
          @media screen and (max-width: 380px) {
            font-size: 18px;
          }
        }
        .form--inputs {
          width: 100%;
          input:first-child {
            display: flex;
            width: 100%;
            padding: 7px 10px 7px 20px;
            align-items: center;
            gap: 10px;
            flex-shrink: 0;
            border-radius: 100px;
            background: transparent;
            border: 2px solid #000;
          }
          input:last-child {
            margin-top: 25px;
            cursor: pointer;
            padding: 7px 10px;
            text-align: center;
            width: 100%;
            border-radius: 100px;
            background: #e1e051;
            color: #000;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            border: 2px solid #e1e051;
            transition: background 0.3s ease-in-out;
            &:hover {
              transition: background 0.3s ease-in-out;
              background: #F3F3B9;
            }
            &:disabled {
              transition: opacity 0.4s ease-in-out;
              opacity: 0.5;
              transition: background 0.3s ease-in-out;
            }
            @media screen and (max-width: 480px) {
              font-size: 14px;
            }
          }
        }
        .checkbox-container {
          margin: 20px 0;
          display: flex;
          align-items: center;
          input {
            position: relative;
            width: 20px;
            height: 20px;
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            -ms-appearance: none; 
            border-radius: 50%;
            border: 1px solid black;
            cursor: pointer;
            &:checked{
              background: black;
            }
          }
          p {
            color: rgba(0, 0, 0, 0.6);
            margin: 0 0 0 10px;
            max-height: 30px;
            max-width: 400px;
            font-size: 12px;
            line-height: normal;
            a {
              color: rgba(0, 0, 0, 0.6);
              text-decoration: underline;
            }
            @media screen and (max-width: 1050px) {
              max-width: 300px;
            }
            @media screen and (max-width: 400px) {
              font-size: 10px;
              min-width: 260px;
              max-width: 260px;
             }
          }
        }
        @media screen and (max-width: 480px) {
          width: 350px;
        }
        @media screen and (max-width: 375px) {
          width: 330px;
        }
      }
      &_nav {
        margin: 10px 0 30px;
        width: 450px;
        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;
          li {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 130px;
            border-radius: 100px;
            background: #e1e051;
            padding: 8px 20px;
            border: 2px solid #e1e051;
            transition: background 0.3s ease-in-out;
            &:hover {
              background: #f3f3b9;
              transition: background 0.3s ease-in-out;
            }
            a {
              color: #000;
              text-align: center;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              @media screen and (max-width: 480px) {
                font-size: 14px;
                min-width: 81px;
              }
            }
          
            &:last-child {
              background: transparent;
              border: 2px solid #000;
            }
            @media screen and (max-width: 480px) {
              min-width: 100px;
              &:nth-child(2) {
                margin: 0;
              }
              &:last-child  {
                margin: 20px 0 0 ;
              }
            }
            @media screen and (max-width: 380px) {
              padding: 8px 10px;
            }
          }
          @media screen and (max-width: 480px) {
            justify-content: space-between;
            max-width: 350px;
            flex-wrap: wrap;
            li {
              &:last-child {
               margin: 20px auto;
              }
            }
          }
        }
        @media screen and (max-width: 480px) {
          width: 350px;
        }
        @media screen and (max-width: 375px) {
          width: 330px;
        }
      }
    }
  }
  @media screen and (max-width: 1188px) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 0 30px;
  }
}
