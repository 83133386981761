.modal--container {
  position: fixed;
  z-index: 99999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  backdrop-filter: blur(7px);
  display: flex;
  align-items: center;
  justify-content: center;
  &_wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    font-size: 30px;
    border-radius: 45px;
    background: #fff;
    min-width: 667px;
    padding: 0 0 40px 20px;
    p {
      margin: 15px 10px 0;
      color: $defColor;
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      max-width: unset;
      width: 100%;
      text-align: start;
      min-height: unset;
      @media screen and (max-width: 1024px) {
        font-size: 30px;
      }
      @media screen and (max-width: 510px) {
        font-size: 20px;
      }
    }
    form {
      margin:0 20px 0 10px;
      p {
        margin: 0;
        padding: 10px 0;
        color: rgba(0, 0, 0, 0.6);
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        @media screen and (max-width: 1024px) {
          font-size: 16px;
        }
        @media screen and (max-width: 510px) {
          font-size: 14px;
          padding: 10px 0 5px;
        }
      }
      input {
        padding: 5px 10px 5px 20px;
        font-size: 18px;
        border-radius: 100px;
        min-height: 40px;
        min-width: 530px;
        border: 2px solid #000;
        &:last-child {
          font-weight: 500;
          padding: 7px 20px;
          display: flex;
          min-width: 138px;
          height: 41px;
          font-size: 22px;
          align-items: center;
          justify-content: center;
          background: $titleColor;
          cursor: pointer;
          transition: opacity 0.4s ease-in-out;
          border: 2px solid #e1e051;
          transition: background 0.3s ease-in-out;
          &:hover {
            transition: background 0.3s ease-in-out;
            background: #F3F3B9;
          }
          &:disabled {
            transition: opacity 0.4s ease-in-out;
            opacity: 0.5;
            transition: background 0.3s ease-in-out;
          }
          @media screen and (max-width: 1024px) {
            font-size: 18px;
          }
          @media screen and (max-width: 510px) {
            height: 36px;
          }
        }
        @media screen and (max-width: 1024px) {
          min-width: 350px;
        }
        @media screen and (max-width: 510px) {
          min-width: 300px;
          min-height: 30px;
          max-height: 36px;
        }
      }
      .politics {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 20px 0;
        input {
          position: relative;
          padding: 0;
          border-radius: 50%;
          min-width: 20px;
          min-height: 20px;
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          -ms-appearance: none;
          border: 1px solid black;
          cursor: pointer;
          &:checked {
            background: black;
          }
        }
        p {
          margin-left: 10px;
          color: rgba(0, 0, 0, 0.6);
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          max-width: 495px;
          a {
            color: rgba(0, 0, 0, 0.6);
            text-decoration: underline;
          }
          @media screen and (max-width: 1024px) {
            max-width: 300px;
          }
          @media screen and (max-width: 510px) {
            max-width: 280px;
            font-size: 9px;
          }
        }
      }
    }
    @media screen and (max-width: 1024px) {
      min-width: unset;
      width: 400px;
    }
    @media screen and (max-width: 510px) {
      width: 350px;
    }
  }
}
