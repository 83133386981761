.trampoTypes--container {
  margin: 110px 30px 80px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  &_item {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    h2 {
      color: #000;
      font-size: 50px;
      font-style: normal;
      font-weight: 500;
      @media screen and (max-width: 1350px) {
        font-size: 35px;
      }
      @media screen and (max-width: 1250px) {
        font-size: 30px;
      }
      @media screen and (max-width: 768px) {
        font-size: 24px;
      }
    }
    p {
      margin-top: 15px;
      max-width: 395px;
      min-height: 130px;
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      @media screen and (max-width: 500px) {
        font-size: 16px;
        max-width: 350px;
      }
      @media screen and (max-width: 375px) {
        font-size: 14px;
      }
    }
    .trampoTypes-image {
      align-self: center;
      width: 265px;
      height: 250px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      .image0 {
        position: relative;
        top: -40px;
        width: 130%;
        height: 130%;
        cursor: pointer;
        transition: background 0.4s ease-in-out;
        background: url("../../assets/images/Types/squircle_ring_without_rubber_sheet.webp")
          center center / cover no-repeat;
        &:hover {
          transition: background 0.4s ease-in-out;
          background: url("../../assets/images/Types/opacitySquircle.jpg")
            center center / cover no-repeat;
        }
      }
      .image1 {
        position: relative;
        top: -40px;
        width: 120%;
        height: 120%;
        cursor: pointer;
        transition: background 0.4s ease-in-out;
        background: url("../../assets/images/Types/square_tiles_without_belt_fabric.webp")
          center center / cover no-repeat;
        &:hover {
          transition: background 0.4s ease-in-out;
          background: url("../../assets/images/Types/opacirySquare.jpg") center
            center / cover no-repeat;
        }
      }
      .image2 {
        position: relative;
        top: -40px;
        width: 120%;
        height: 120%;
        cursor: pointer;
        transition: background 0.4s ease-in-out;
        background: url("../../assets/images/Types/squircle_mound_without_rubber_sheet.webp")
          center center / cover no-repeat;
        &:hover {
          transition: background 0.4s ease-in-out;
          background: url("../../assets/images/Types/opacityMound.jpg") center
            center / cover no-repeat;
        }
      }
    }
    &:nth-child(2) {
      order:3;
    }
    &:nth-child(3) {
      margin: 0 30px;
      @media screen and (max-width: 745px) {
        margin: 0;
      }
    }

  }
}
