.custom--container {
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c0c0c033;
  padding: 0 30px;
  &_descr {
    h2 {
      color: #000;
      font-size: 50px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 15px;
      @media screen and (max-width: 1350px) {
        font-size: 45px;
      }
      @media screen and (max-width: 1250px) {
        font-size: 40px;
      }
      @media screen and (max-width: 768px) {
        font-size: 30px;
      }
    }
    p {
      color: #000;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      max-width: 450px;
      &:nth-child(3) {
        color: #000;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        margin: 20px 0;
        @media screen and (max-width: 768px) {
          font-size: 18px;
        }
        @media screen and (max-width: 500px) {
          font-size: 16px;
        }
        @media screen and (max-width: 375px) {
          font-size: 14px;
        }
      }
      @media screen and (max-width: 768px) {
        font-size: 22px;
      }
      @media screen and (max-width: 500px) {
        font-size: 18px;
      }
      @media screen and (max-width: 375px) {
        font-size: 16px;
      }
    }
  }
  &_items {
    margin-top: 150px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .custom-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .custom--item {
        cursor: pointer;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #d2d2d2;
      }
      .custom--item.active {
        background: $titleColor;
      }
      p {
        margin-top: 10px;
        color: $defColor;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        min-height: 27px;
      }
      &:nth-child(2) {
        margin: 0 15px 0 30px;
      }
      &:nth-child(3) {
        margin: 0 30px 0 15px;
      }
    }
    @media screen and (max-width: 900px) {
      position: absolute;
      bottom: 30px;
    }
  }
  .custom-design_image {
    margin-left: 50px;
    height: 500px;
    width: 550px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    @media screen and (max-width: 1124px) {
      margin-left: 0;
    }
    @media screen and (max-width: 900px) {
      margin-bottom: 100px;
    }
    @media screen and (max-width: 650px) {
      width: 450px;
    }
    @media screen and (max-width: 475px) {
      height: 350px;
    }
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
    height: 100%;
    padding: 75px 30px 20px;
    &_descr {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      h2 {
        width: 100%;
      }
      p {
        width: 100%;
        max-width: unset;
      }
    }
  }
  @media screen and (max-width: 430px) {
   &_descr {
    p {
      max-width: 350px;
    }
   }
  }
  @media screen and (max-width: 900px) {
    position: relative;
  }
}
