.install--container {
  margin-top: 240px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  &_title {
    text-align: center;
    color: #000;
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
  }
  &_types {
    display: flex;
    align-items: center;
    justify-content: center;
    .install-type {
      margin-top: 30px;
      h3 {
        padding-left: 40px;
        color: #000;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
      }
      p {
        padding-left: 40px;
        margin: 15px 0 20px;
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        max-width: 350px;
      }
      .feild {
        min-width: 500px;
        min-height: 330px;
        border-radius: 46px;
        background: #d9d9d9;
      }
      &:first-child {
        margin-right: 30px;
      }
    }
  }
  .images {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 140px;
    div {
      width: 100%;
      max-height: 700px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
