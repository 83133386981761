.contacts--container {
  padding-top: 220px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 150px;
  .contacts--wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 90px;
    min-height: 521px;
    .contacts--text {
      &_about {
        margin-top: -15px;
        margin-bottom: 75px;
        h2 {
          color: #000;
          font-size: 50px;
          font-style: normal;
          font-weight: 500;
          @media screen and (max-width: 1350px) {
            font-size: 45px;
          }
          @media screen and (max-width: 1250px) {
            font-size: 40px;
          }
          @media screen and (max-width: 768px) {
            font-size: 35px;
          }
          @media screen and (max-width: 450px) {
            font-size: 30px;
          }
        }
        @media screen and (max-width: 1050px) {
          margin-bottom: 50px;
        }
        @media screen and (max-width: 450px) {
          margin-bottom: 30px;
        }
      }
      .about-container {
        max-width: 190px;
        .about__item {
          border-radius: 100px;
          border: 2px solid #000;
          padding: 7px 12px;
          margin-bottom: 26px;
          width: 100%;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: border 0.3s ease-in-out;
          &:hover {
            transition: border 0.3s ease-in-out;
            border: 2px solid #818181;
          }
          a {
            overflow: hidden;
            color: #000;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            transition: color 0.3s ease-in-out;
            &:hover {
              color: rgba(0, 0, 0, 0.51);
              transition: color 0.3s ease-in-out;
            }
            @media screen and (max-width: 1050px) {
             font-size: 16px;
            }
          }
          @media screen and (max-width: 1050px) {
            &:nth-child(2) {
              margin-left: 20px;
            }
          }
          @media screen and (max-width:450px) {
            margin-bottom: 10px;
          }
        }
        @media screen and (max-width: 1050px) {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
      @media screen and (max-width: 450px) {
        margin-bottom: 20px;
      }
    }
    @media screen and (max-width: 1050px) {
      display: flex;
      justify-content: flex-start;
      max-width: 500px;
      margin-right:0;
      margin-bottom: 60px;
      min-height: unset;
    }
    @media screen and (max-width: 450px) {
      margin-bottom: 35px;
    }
  }
  .contacts--links {
    h3 {
      color: #000;
      font-size: 31px;
      font-style: normal;
      font-weight: 500;
      line-height: 39px; /* 125.806% */
    }
    &__wrapper {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      min-width: 400px;

      .link_item {
        border: 2px solid #000;
        border-radius: 100px;
        padding: 8px 20px;
        transition: border 0.3s ease-in-out;
        &:hover {
          transition: border 0.3s ease-in-out;
          border: 2px solid #818181;
        }
        a {
          color: #000;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          transition: color 0.3s ease-in-out;
          &:hover {
            color: rgba(0, 0, 0, 0.51);
            transition: color 0.3s ease-in-out;
          }
          @media screen and (max-width: 1050px) {
            font-size: 14px;
           }
        }
        @media screen and (max-width: 1050px) {
          padding: 4px 15px;
         }
      }
      @media screen and (max-width: 1285px) {
        min-width: 360px;
      }
      @media screen and (max-width: 400px) {
        min-width: 300px;
       }
    }
  }
  @media screen and (max-width: 1050px) {
    flex-direction: column;
    align-items: center;
    margin: 0 20px 100px;
  }
  @media screen and (max-width: 500px) {
    padding-top: 120px;
    margin: 0 20px 55px;
  }
}
