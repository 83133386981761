.dimensions--container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 50px;
  &_files {
    margin-top: 20px;
    h3 {
      color: #000;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      @media screen and (max-width: 1200px) {
        margin-bottom: 20px;
      }
    }
    .files_link {
      margin: 40px 0 70px;
      max-width: 500px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .link_item {
        cursor: pointer;
        border-radius: 100px;
        border: 2px solid #000;
        padding: 7px 12px;
        p,
        a {
          color: #000;
          font-size: 23px;
          font-style: normal;
          font-weight: 500;
        }
        &:nth-child(2) {
          margin: 0 26px;
          @media screen and (max-width: 372px) {
            margin: 0 0 0 26px;
          }
        }
        &:nth-child(3) {
          @media screen and (max-width: 449px) {
            margin: 26px 0 0;
          }
        }
        &:nth-child(4) {
          margin: 40px 26px 0 0;
          @media screen and (max-width: 449px) {
            margin: 26px 0 0 26px;
          }
        }
        &:nth-child(5) {
          margin: 40px 0 0;
        }
        @media screen and (max-width: 1000px) {
          p,
          a {
            font-size: 20px;
          }
        }
        @media screen and (max-width: 500px) {
          p,
          a {
            font-size: 16px;
          }
        }
      }
      @media screen and (max-width: 1200px) {
        max-width: 560px;
        margin: 40px 0;
      }
    }
    p {
      max-width: 505px;
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
    }
    @media screen and (max-width: 1200px) {
      margin: 0 20px 40px;
      p {
        max-width: 550px;
      }
    }
    @media screen and (max-width: 600px) {
      text-align: center;
    }
  }
  &_sizes {
    margin-left: 50px;
    background: $titleColor;
    border-radius: 41px;
    padding: 20px 36px;
    display: flex;
    flex-direction: column;
    h3 {
      color: #000;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
    }
    .dimensions--text {
      p {
        margin: 20px 0;
        max-width: 505px;
        color: #000;
        font-size: 23px;
        font-style: normal;
        font-weight: 500;
        &:last-child {
          span {
            font-size: 23px;
            font-weight: 500;
          }
          color: #000;
          font-size: 19px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
    .dimensions--image {
      align-self: center;
      width: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 200px;
      overflow: hidden;
      img {
        transform: scale(1.5);
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    @media screen and (max-width: 1200px) {
      margin-left: 0;
      align-self: center;
    }
  }
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
}
